import React, {useEffect, useState} from "react";
import services from "../../API_SERVICES/services";
import { isId } from "../../Storage(Methods)/storage";


const Dalle = () => {
    const [projectName, setProjectName] = useState("");
    const [imageGenerated, setImage] = useState();
    const [loader, setLoader] = useState(true);
    const [prompt, setPrompt] = useState("");
    
    // Getting current project name
    useEffect(() => {
        // Getting the project name
        var projid = isId();
        if (projid !== -1) {
          const apiname1 = "fetch-project-id";
          services
            .getbasic(apiname1, projid)
            .then((response) => {
              if (response === null || response.data == null) {
              }
              else {
                setProjectName(response.data.project_title);
              }
            }).catch((error) => {
            });
        }
    }, []);

    // Prompt functionality
    const promptChange = (event) => {
        setPrompt(event.target.value);
    };

    // Calling api on generate click
    const generateImage = async (e) => {
        let apiname = "dalle-generate-image"
        let data = {
            "prompt": prompt
        }
        setLoader(false)
        await services.post(apiname, data)
            .then((response)=>{
                setLoader(true)
                setImage("data:image/png;base64, "+response.data.data.base64);
            })
            .catch((error)=>{
                setLoader(false)
            })
    }

    // Downloading the image 
    const downloadImage = async () => {
         // Create a link element
         const link = document.createElement("a");
         link.href = imageGenerated;
         
         // Set the download attribute with a filename
         link.download = "generated_image.png";
         
         // Trigger the download
         link.click();
    }
    return (
        <>
            <p className="chatgptcontainer-header ps-3">{projectName}/MODELS/ DALL-E-3/ Generate image</p>
            <div className="dalle-container p-4">
                <h4 className="text-center" style={{color: " #0000008A"}}>Enter a prompt: </h4>
                <input value={prompt} onChange={promptChange}  className="dalle-image-input mt-2" type="text"/>
                <div className="dalle-btn-wrapper">
                    <button onClick={generateImage} className="dalle-generate-btn px-4 py-1 mt-3 text-center">Generate</button>
                </div>
                <div className="dalle-image-container">
                    { loader ? 
                        <>
                        { imageGenerated ?
                            <div className="mt-3">
                                <img className="dalle-generated-image" src={imageGenerated} alt="Dalle-img"></img>
                                <div className="dalle-btn-wrapper">
                                    <button onClick={downloadImage} className="dalle-generate-btn px-4 py-1 mt-2 text-center">Download</button>
                                </div>
                            </div>
                            : null
                        }
                        </>
                        :
                        <div className="dalle-loader mt-3 p-3">
                            <div className="dalle-circle"></div>
                            <div className="dalle-circle"></div>
                            <p className="text-center mt-3">Generating image...</p>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Dalle;
