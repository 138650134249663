import React, { useState } from "react";
import { isAuth, signout } from "../../Storage(Methods)/storage";
import siteLogo from "../../Assets/Images/siteLogo.png";
import { PopupModal } from "../../Screens/DataPrivacy/PopupModal";
import { useNavigate } from "react-router-dom";
import { useMenuContext } from './menuContext';
import { ExplorerNavBar } from "../../Screens/Data_Contribution/explorer_navbar";

const Navbar = () => {
  const { showAdditionalMenu, activeMenuItem } = useMenuContext();
  const [showNavbar, setShowNavbar] = useState(true);
  const itemArray = [
    { name: "PROJECTS", value: "/data-contribute" },
    { name: "FRAMES", value: "/public-frames" },
    { name: "DATA", value: "/public-dataset" },
    { name: "MODELS", value: "/model-explorer" },
  ];

  const [logval, setLogval] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const completeUrl = window.location.href;
    const sharedUrl = completeUrl.includes("/shared/")
     || completeUrl.includes("/sharedUploadData") 
     || completeUrl.includes("/embedPage") 
    //  || completeUrl.includes("/project-dbse")
    setShowNavbar(!sharedUrl);
  }, []);

  return (
    <>
    {showNavbar && (
  <header>
  <div className="logo text-hover" onClick={() => navigate("/projectsetup", { replace: true })}>
<svg width="50" height="32" viewBox="0 0 840 600" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M839.813 300C839.813 465.685 705.498 600 539.813 600C374.127 600 239.813 465.685 239.813 300C239.813 134.315 374.127 0 539.813 0C705.498 0 839.813 134.315 839.813 300Z" fill="#0C090A"/>
<path d="M178.17 38.7587C182.699 25.9541 194.702 17.4079 208.156 17.4079C225.743 17.4079 240 31.8176 240 49.5929V548.353C240 567.272 224.826 582.608 206.108 582.608H33.9204C10.4789 582.608 -5.88429 559.132 2.00661 536.823L178.17 38.7587Z" fill="#0C090A"/>
</svg>

  </div>
  <div className="content-head">
    {!isAuth() ? (
      <div className="user-profile-tab" >
        <a href="/login" >LOGIN</a>
        <a href="/#" >SEARCH</a>
      </div>
    ) : (
      <>
        <div className="login-contents">
          {showAdditionalMenu && (
            <di className="menubar-items">
              <ExplorerNavBar labels={itemArray} isActive={activeMenuItem} />
            </di>
          )}
          <label>
            <div
              className="loggeduser"
              onClick={() =>
                navigate("/UserAddNewProject", { replace: true })
              }
            >
              <div className="d-flex align-items-center text-hover">
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "lightgreen",
                  }}
                  className="me-2"
                ></div>
                {isAuth().username}
              </div>
            </div>
          </label>
          <label>
            <a style={{ textDecoration: "none", color: "black" }} onClick={() => setLogval(!logval)} className="text-hover">LOGOUT</a>
          </label>
        </div>
      </>
    )}
    <div className="hidden-itemss">
      <i className="fa-solid fa-bars" ></i>
    </div>
  </div>

</header>
  )}

{!logval ? null : (
  <PopupModal>
    <h5 className="mt-4 mb-4 text-center">
      ARE YOU SURE YOU WANT TO LOGOUT ?
    </h5>
    <div className="d-flex justify-content-between">
      <button
        type="button"
        className="backbtn"
        onClick={() => setLogval(!logval)}
      >
        BACK
      </button>
      <button
        type="button"
        className="logoutbtn"
        onClick={() => signout()}
      >
        LOGOUT
      </button>
    </div>
  </PopupModal>
)}
    
      
      </>
  );
};
export default Navbar;
