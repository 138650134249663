import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import debounce from "lodash.debounce";
import path from "path";
import services from "../../API_SERVICES/services";
import toast from "react-hot-toast";
import { isAuth ,signout} from "../../Storage(Methods)/storage";
import { PopupModal } from "../DataPrivacy/PopupModal";
import Pagination from "../../Components/Pagination";
import iconMp3 from '../../Assets/Images/mp3_icon.png'
import editpic from "../../Assets/Images/edit.png";
import bulletpic from "../../Assets/Images/Ellipse.png";
import CallForData from "../../Components/ProjectDBSEComponents/CallForData";
import UploadData from "../../Components/ProjectDBSEComponents/UploadData";
import LocationDataDisplay from "../../Components/ProjectDBSEComponents/LocationDataDisplay";
import { getFileIcon } from "../../utils/general";
import Ellipse from '../../Assets/Images/Ellipse.png'
// import ModelViewer from "../../Components/ProjectDBSEComponents/ModelViewer";
import ThreeDViewer from "../../Components/ProjectDBSEComponents/ThreeDViewer";
import { useMenuContext } from "../../Components/Navbar/menuContext";
import { ExplorerNavBar } from "../Data_Contribution/explorer_navbar";

const Projectcontributions = () => {
  let navigate = useNavigate();
  const [logval, setLogval] = useState(false);
  const { showAdditionalMenu, activeMenuItem } = useMenuContext();
  const itemArray = [
    { name: "PROJECTS", value: "/data-contribute" },
    { name: "FRAMES", value: "/public-frames" },
    { name: "DATA", value: "/public-dataset" },
    { name: "MODELS", value: "/model-explorer" },
  ];
  const [isHovered, setIsHovered] = useState(false);
  const [global, setglobal] = useState({
    screen5: false,
    screen6: false,
    screen7: false,
    datacontributions: "",
    loading: false,
    fetched: "",
    image: "",
    extensions: [],
    editmode: false,
    screencall: false,
  });

  const [MasterChecked, setMasterChecked] = useState(false)
  const [selectedlist, setselectedlist] = useState([]);
  const [details, setDetails] = useState({
    author: "",
    q1: "",
    q2: "",
    permissions1: "",   
    permissions2: "",
    permissions3: "",
  });
  const location = useLocation();
  const projName= location.state.data.project_title;
  const projDesc = location.state.data.project_desc;
  const pId = location.state.data.pId;
  const {
    screen5,
    screen7,
    datacontributions,
    loading,
    fetched,
    extensions,
    editmode,
    screencall,
  } = global;
  const [currentPage, setCurrentPage] = useState(1);
  // No of Records to be displayed on each page   
  // const [recordsPerPage, setRecordsPerPage] = useState(10);
  const recordsPerPage = 10;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = datacontributions?.data?.length > 0 ? Math.ceil(datacontributions?.data.length / recordsPerPage) : 1;
  const [filterParam, setFilterParam] = useState(['']);
  const [searchParam] = useState(["video", "author"]);
  const [q, setQ] = useState("");
  const [modeltypes, setModeltypes] = React.useState([
    {
      "name": "Sound",
      "selected": false,
    },
    {
      "name": "Text",
      "selected": false,

    },
    {
      "name": "Image",
      "selected": false,

    },
    {
      "name": "Video",
      "selected": false,
    }])
  const [values, setValues] = useState({
    DataCallTitle: '',
    title: '',
    message: '',
    dataTypes: '',
    call_for_data_fields: '',
    consent_and_permissions: '',
    core_question_author_name: '',
    core_question_how_this_sourced: '',
    core_question_where_did_this_come: '',
    optional_question_data: '',
    consent_optional_email: '',
    consent_donation_paid: '',
    privacy:'',
    contributionTypes:[]
  });
  
  const [projectData, setProjectData] = useState({
    pId:'',
    project_title:'',
    callfordata_enabled:'',
    DataCallTitle: '',
    title: '',
    message: '',
    dataTypes: '',
    call_for_data_fields: '',
    consent_and_permissions: '',
    core_question_author_name: '',
    core_question_how_this_sourced: '',
    core_question_where_did_this_come: '',
    optional_question_data: '',
    consent_optional_email: '',
    consent_donation_paid: '',
    call_for_data_code:'',
   
  });

  const [callenabled, setcallenabled] = useState(false);
  const [togglecall, setTogglecall] = useState(false);
  const [isUploadDataLoading, setIsUploadDataLoading] = React.useState(false);
  const [finalCount, setFinalCount] = React.useState(0)
  useEffect(() => {
    sessionStorage.setItem(
      "identifier",
      JSON.stringify(location?.state?.data?.pId)
    );
    if (location?.state?.data?.project_title === "ART SEARCH") {
      getArtData()
    } else {
      loaddata();
      fetchPerticularProj();
      setcallfordata();
    }
  }, []);

  const fetchPerticularProj = () => {
    const apiname1 = "fetch-project-id";
    services.getbasic(apiname1, location?.state?.data?.pId)
      .then((response) => {
        if(response?.data?.callfordata_enabled === "Y"){
          setcallenabled(true)
        }
        setProjectData({
          ...projectData,
          pId:response?.data?.pId,
          project_title:response?.data?.project_title,
          DataCallTitle: response?.data?.call_for_data_call_title,
          title: response?.data?.callfordata_title,
          message: response?.data?.callfordata_message,
          dataTypes: response?.data?.callfordata_type,
          call_for_data_fields: JSON.parse(response?.data?.call_for_data_fields),
          consent_and_permissions: response?.data?.consent_and_permissions,
          core_question_author_name: response?.data?.core_question_author_name,
          core_question_how_this_sourced: response?.data?.core_question_how_this_sourced,
          core_question_where_did_this_come: response?.data?.core_question_where_did_this_come,
          optional_question_data: JSON.parse(response?.data?.optional_question_data),
          callfordata_enabled:response?.data?.callfordata_enabled,
          call_for_data_code: response?.data?.call_for_data_code,
       
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  const getArtData = (data) => {
    const apiname = "load-videos";
    services
      .getS3Data(apiname)
      .then((response) => {
        const tempData = [];
        response.data.videos.forEach((item, index) => {
          let tempObj = {
            dcId: item.id,
            pId: item.id,
            user_id: item.id,
            video: item.s3_url,
            archive: item.archive,
            collection: item.collection,
            authors: item.whoFoundThis,
            whereComeFrom: item.whereComeFrom
          }
          tempData.push(tempObj)
        });
        const tempJson = {
          data: {
            message: "Data fetched successfully",
            data: tempData
          }
        }
        setglobal({
          ...global,
          datacontributions: tempJson.data,
          loading: true,
          // extensions: getExtensionList(response),
          screen6: false,
        });
      })
      .catch((err) => {
        console.log("Error ===> ", err);
      });
  };


  const setcallfordata = () => {
    let arr = [];
    if (location?.state?.data?.callfordata_enabled === "Y") {
      const newtype = [...modeltypes];
      setcallenabled(true);
      for (let i = 0; i < newtype.length; i++) {
        if (location?.state?.data?.callfordata_type.includes(newtype[i].name)) {
          newtype[i].selected = true;
          arr.push(newtype[i]);
        }
      }
      setValues({
        ...values,
        pId:location?.state?.data?.pId,
        DataCallTitle: location?.state?.data?.call_for_data_call_title,
        title: location?.state?.data?.callfordata_title,
        message: location?.state?.data?.callfordata_message,
        dataTypes: location?.state?.data?.callfordata_type,
        call_for_data_fields: JSON.parse(location?.state?.data?.call_for_data_fields),
        consent_and_permissions: location?.state?.data?.consent_and_permissions,
        core_question_author_name: location?.state?.data?.core_question_author_name,
        core_question_how_this_sourced: location?.state?.data?.core_question_how_this_sourced,
        core_question_where_did_this_come: location?.state?.data?.core_question_where_did_this_come,
        optional_question_data: JSON.parse(location?.state?.data?.optional_question_data),
        privacy: location?.state?.data?.project_privacy,
        contributionTypes:location?.state?.data?.contribution_types ? JSON.parse(location?.state?.data?.contribution_types) : []
      });
    }
  }

 


  // Handle mouse enter and leave events to track hover state
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // const currentRecords = datacontributions?.data?.length > 0?datacontributions?.data.slice(indexOfFirstRecord,  indexOfLastRecord):[];
  // const onFileChange = (e) => {
  //   setglobal({ ...global, image: e.target.files[0] });
  // };
  // const checkhandler = (e) => {
  //   setDetails({ ...details, [e.target.name]: e.target.value });
  // };
  const inputStyles = {
    width: "100%",
    borderRadius: "20px",
    padding: "7px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
  };
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  const dropinputStyles = {
    width: "100%",
    padding: "5px",
    fontSize: "16px",
    lineHeight: "1",
    border: "1px solid rgba(187, 189, 179, 1)",
    borderRadius: "20px",
    background: 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)no-repeat right',
    backgroundColor: "rgba(32, 159, 140, 0.05)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "right 20px top 0.4em",
    height: "37px",
    paddingLeft: "20px",
    color:"rgba(0, 0, 0, 0.7)"
  };
  
  const buttonStyles = {
    padding: "20px",
    borderRadius: "50px",
    width: "45%",
    display: "block",
    // margin: "100px auto 0 auto",
    color: "rgba(255, 255, 255, 0.7)",
    border: "none",
    backgroundColor: isHovered ? "black" : "rgba(0, 0, 0, 0.54)",
    textAlign: "center",
    // marginBottom: "50px",
  };
  const imageStyle = {
    width: "30px",
    height: "30px",
  };
  const loaddata = () => {
    let apiname = "fetch-project-data";
    const project = true;
  
    services
      .get(apiname, project)
      .then((response) => {
        const parsedData = response?.data
          ?.filter((item) => !item.video?.includes("dummyfileforTextUploadonly")) // Exclude items with "drawing-prompt" in video
          ?.map((item) => {
            return {
              ...item,
              location: item.location ? JSON.parse(item.location) : null,
            };
          });
  
        const modifiedResponse = {
          ...response,
          data: parsedData,
        };
  
        setglobal({
          ...global,
          datacontributions: modifiedResponse,
          loading: true,
          extensions: getExtensionList(modifiedResponse),
        });
      })
      .catch((error) => {
        console.log("error==========>", error.response?.data?.error || error.message);
      });
  };
  
  

  function search(items) {
    return items.filter((item) => {
      if ((path.basename(item?.video)).includes(filterParam)) {
        return searchParam.some((newItem) => {
          if (item[newItem] !== null) {
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(q.toLowerCase()) > -1
            );
          }
        });
      } else if (filterParam === "") {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }
  const handleChange = (e) => {
    setFilterParam(e.target.value);
    //filter
  };
  const getExtensionList = (response) => {
    var ext = [];
    for (var i = 0; i < response?.data?.length; i++) {
      var filename = path.basename(response?.data[i]?.video);
      var extensions = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename).toString() : undefined
      if (!ext.includes(extensions) && extensions !== undefined) {
        ext.push(extensions);
      }
    }
    return ext;
  }

  
  const completeUpload = async (childStates) => {
    setIsUploadDataLoading(true);
    if (childStates.image) {
      dataUpload(childStates);
      const id = sessionStorage.getItem("id")
      childStates.ids = [...childStates.ids, { id: id }];
    }
    const apiname = "data-details";
    var per = "";
    if (childStates.permissionOne !== '') {
      per = per + childStates.permissionOne + ";";
    }
    if (childStates.permissionTwo !== '') {
      per = per + childStates.permissionTwo + ";";
    }
    if (childStates.permissionThree !== '') {
      per = per + childStates.permissionThree;
    }
    const data = {
      author: childStates.author ? childStates.author : isAuth()?.username,
      q1: childStates.whereDidThisComeFrom ? childStates.whereDidThisComeFrom : "N/A",
      q2: childStates.howWasThisSourced ? childStates.howWasThisSourced : "N/A",
      permissions: per,
      id: childStates.ids.length > 0 ? childStates.ids : [] ,
      call_for_data_fields: JSON.stringify(childStates.dataFields) ,
      optional_question_data: `[]`,
      consent_optional_email: childStates.email,
      consent_donation_paid: childStates.donationOrPaid,
    };
    services
      .post(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
        setDetails({ ...details, author: "", q1: "", q2: "", permissions1: "", permissions2: "", permissions3: "" });
        setglobal({ ...global, image: "", });
        loaddata();
        setIsUploadDataLoading(false);
        setFinalCount(4);
      })
      .catch((err) => {
        setIsUploadDataLoading(false);
        toast.error(err.response.data.error);
      });
  };
  const DelayApiCall = useCallback(
    debounce(
      (apiname, data) =>
        services
          .post(apiname, data)
          .then((response) => {
            toast.success(response.data.message);
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          }),
      1500
    ),
    []
  );
  let handleChangeform = (e) => {
    let newfetched = fetched;
    newfetched.q1 = e.target.value;
    setglobal({ ...global, fetched: newfetched });
    const data = { id: fetched.dcId, q1: e.target.value, q2: fetched.q2, author: fetched.author }
    DelayApiCall("edit_dataset", data);
  }
  let handleChangeform2 = (e) => {
    let newfetched = fetched;
    newfetched.q2 = e.target.value;
    setglobal({ ...global, fetched: newfetched });
    const data = { id: fetched.dcId, q1: fetched.q1, q2: e.target.value, author: fetched.author }
    DelayApiCall("edit_dataset", data);
  }
  let handleChangeauthor = (e) => {
    let newfetched = fetched;
    newfetched.author = e.target.value;
    setglobal({ ...global, fetched: newfetched });
    const data = { id: fetched.dcId, q1: fetched.q1, q2: e.target.value, author: e.target.value }
    DelayApiCall("edit_dataset", data);
  }

  // This function is used to upload the image only on S-3 bucket
  const dataUpload = (childStates) => {
    const apiname = "data_upload";
    var formData = new FormData();
    formData.append("dataUpload", childStates.image);
    formData.append("pId", location?.state?.data?.pId);
    formData.append("user_id", isAuth().id);
    formData.append("contributor", isAuth().username);
    services
      .post(apiname, formData)
      .then((response) => {
        toast.success(response.data.message);
        sessionStorage.setItem(
          "data",
          JSON.stringify(response?.data?.data?.video)
        );
        sessionStorage.setItem(
          "id",
          JSON.stringify(response?.data?.data?.dcId)
        );
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setglobal({ ...global, image: "" })
      });
  };


  const onMasterCheck = (e) => {
    setMasterChecked(e.target.checked);
    // SelectedList: this.state.List.filter((e) => e.selected),
    let tempList = datacontributions;
    // Check/ UnCheck All Items
    tempList?.data.map((data) => (data.selected = e.target.checked));
    setglobal({
      ...global,
      datacontributions: tempList,

    });
    setselectedlist(datacontributions.data.filter((e) => e.selected))
  }

  const onItemCheck = (e, item) => {
    item.selected = e.target.checked;
    let tempList = datacontributions;

    tempList?.data.map((data) => {
      if (data.dcId === item.dcId) {
        data.selected = e.target.checked;
      }
      return data;
    });

    setglobal({
      ...global,
      datacontributions: tempList,

    });
    setselectedlist(datacontributions.data.filter((e) => e.selected))
  }

  const handleAction = (e) => {
    if (e.target.value === "Download") {
      downloadItems1();
    } else if (e.target.value === "Delete") {
      deleteItems();
    }
  }

  const downloadItems1 = () => {
    for (var i = 0; i < selectedlist?.length; i++) {

      if (selectedlist[i].video.match(/\.(png|jpg|jpeg)$/)) {

        fetch(selectedlist[i].video, {
          method: "GET",
          cache: "no-cache"

        }).then(response => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "image.png"); //or any other extension
            link.click();
          });
        }).catch(err => {
          console.log(err);
        });
      }
      if (selectedlist[i].video.match(/\.(mp3|aac)$/)) {
        fetch(selectedlist[i].video, {
          method: "GET",
          cache: "no-cache"
        }).then(response => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "audio.mp3"); //or any other extension
            link.click();
          });
        }).catch(err => {
          console.log(err);
        });
      }
      if (selectedlist[i].video.match(/\.(mp4)$/)) {
        fetch(selectedlist[i].video, {
          method: "GET",
          cache: "no-cache"
        }).then(response => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "video.mp4"); //or any other extension
            link.click();
          });
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  // const downloadItems = () => {
  //   for (var i = 0; i < selectedlist?.length; i++) {
  //     if (selectedlist[i].video.match(/\.(png|jpg|jpeg)$/)) {
  //       console.log(selectedlist[i]);
  //       const data = {
  //         key: selectedlist[i].video
  //       };
  //       services
  //         .post("getimage-buffer", data)
  //         .then((response) => {
  //           console.log(response);
  //           var a = document.createElement("a");
  //           a.href = "data:image/png;base64," + response.data;
  //           a.download = "Image.png";
  //           a.click();
  //         })
  //         .catch((err) => {
  //         });
  //     }
  //   }

  // }
  const deleteItems = () => {
    let dids = [];
    for (var i = 0; i < selectedlist?.length; i++) {
      dids.push(selectedlist[i].dcId);
    }
    const data = {
      dcId: dids
    };
    services
      .post("delete-data", data)
      .then((response) => {
        toast.success("Deleted successfully");
        loaddata();

      }).catch((err) => {
        console.log(err);
      });
  }
  // const handlechangetype = (index) => {
  //   setValues({ ...values, type: [] });

  //   const newtype = [...modeltypes];
  //   newtype[index].selected = !modeltypes[index].selected;
  //   setModeltypes(newtype);
  //   let arr = [];
  //   for (let i = 0; i < newtype.length; i++) {
  //     if (newtype[i].selected) {
  //       arr.push(newtype[i]);
  //     }
  //   }
  //   setValues({ ...values, type: arr });

  // };
  const oncalldataOpen = () => {
    if (!callenabled) {
      setValues({
        ...values, title: '',
        message: '',
        type: []
      });
      setTogglecall(false);
    }
    else {
      setTogglecall(true);

    }
    setglobal({ ...global, screencall: !screencall });
  }
  const onSubmitData = (states) => {
    setglobal({ ...global, screencall: !screencall });
    setTogglecall(false);

    const joinedStr = states?.dataTypes.map((list) => list.value).join(",")
    const data = {
      pId: location?.state?.data?.pId,
      msg: states?.titleMessage,
      type: joinedStr,
      title_tagline: states?.title,
      data_call_title: states?.DataCallTitle,
      call_for_data_fields: JSON.stringify(states?.fieldsData), // Data type is Array of object in String
      core_question_author_name: states?.author,
      core_question_where_did_this_come: "",
      core_question_how_this_sourced: states?.howWasThisSourced,
      optional_question_data: JSON.stringify(states?.optionalData), // Data type is Array of Object in String
      consent_and_permissions: `${states?.permissionOne};${states?.permissionTwo};${states?.permissionThree}`,
      contribution_types:JSON.stringify(states?.contributionTypes)
    };
    const apiname = '/activate-callfordata'
    services
      .post(apiname, data)
      .then((response) => {
        setcallenabled(true);
        toast.success(response.data.message);
        fetchPerticularProj();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  }
  
  const unPublishCallForData = (data) => {
    services.put("unpublish-callfordata", data)
      .then((response) => {
        toast.success(response?.data?.message);
        setcallenabled(false)
        setglobal({ ...global, screencall: !screencall });
        fetchPerticularProj();
      }).catch((err) => {
        console.log(err);
      });
  }

  const deleteCallForData = (data) => {
    services.delete(`delete-callfordata/${data}`)
      .then((response) => {
        toast.success(response?.data?.message);
        setcallenabled(false)
        setglobal({ ...global, screencall: !screencall });
        fetchPerticularProj();
      }).catch((err) => {
        console.log(err);
      });
  }

  const handleGenerateLink = (data) => {
    services.post("generatelink-callfordata",data)
    .then((response) => {
      toast.success(response?.data?.message);
    }).catch((err) => {
      console.log("error",err);
    });
  }


  // const handleDatacall = (e) => {
  //   const { name, value } = e.target;
  //   setValues({ ...values, [name]: value });
  // };



  const changeFileExtension = (fileName) => {
    if (fileName.endsWith('.png') && fileName.startsWith('drawing-prompt')) {
      return fileName.replace('.png', '.svg');
    }
    return fileName;
  }

  const getFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const filename = urlObject.pathname.split('/').pop();
    return filename;
};

  return (
    <>
      {location.state === undefined ? (
        <h4
          style={{
            fontSize: "35px",
            fontWeight: "lighter",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50% , -50%)",
          }}
        >
          {" "}
          404 PAGE NOT FOUND
        </h4>
      ) : (
        <div className="projContribution-main-container" style={{backgroundColor:"rgba(241, 239, 233, 1)",height:"max-content"}} >
         <header>
  <div className="logo text-hover" onClick={() => navigate("/projectsetup", { replace: true })} >
<svg width="50" height="32" viewBox="0 0 840 600" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M839.813 300C839.813 465.685 705.498 600 539.813 600C374.127 600 239.813 465.685 239.813 300C239.813 134.315 374.127 0 539.813 0C705.498 0 839.813 134.315 839.813 300Z" fill="#0C090A"/>
<path d="M178.17 38.7587C182.699 25.9541 194.702 17.4079 208.156 17.4079C225.743 17.4079 240 31.8176 240 49.5929V548.353C240 567.272 224.826 582.608 206.108 582.608H33.9204C10.4789 582.608 -5.88429 559.132 2.00661 536.823L178.17 38.7587Z" fill="#0C090A"/>
</svg>
  </div>
  <div className="custom-content-head" 
  style={{display:"flex",alignItems:"center",gap:"15px"}}>
      <>
        <div className="login-contents" >
          {showAdditionalMenu && (
            <div className="menubar-items">
              <ExplorerNavBar labels={itemArray} isActive={activeMenuItem} />
            </div>
          )}
           <div className="projNameBox" style={{marginBottom:"0"}}>
              <div className="d-flex align-items-center"  style={{ textTransform:"uppercase" ,color:"rgba(0, 0, 0, 0.7)"}}>
                {projName ? projName : "PROJECT"}
              </div></div>
          <label>
         
            <div
              className="customloggeduser"
              style={{
                backgroundColor:"rgba(32, 159, 140, 1)",
              padding:"6px 10px",
              borderRadius:"20px",
              cursor:"pointer",
              fontSize:"14px",
              color:"white",
              width:"max-content",
              textTransform:"uppercase"

            }}
              onClick={() =>
                navigate("/UserAddNewProject", { replace: true })
              }
            >
             
              <div className="d-flex align-items-center text-hover">
               
                <img src={Ellipse} alt="elipse" style={{marginRight:"5px"}}/>
                {isAuth().username}
              </div>
            </div>
          </label>
          <label>
            <a style={{ textDecoration: "none", color: "black" }} onClick={() => setLogval(!logval)} className="text-hover">LOGOUT</a>
          </label>
        </div>
      </>
    
    <div className="hidden-itemss">
      <i className="fa-solid fa-bars" ></i>
    </div>
  </div>
</header>
{!logval ? null : (
  <PopupModal>
    <h5 className="mt-4 mb-4 text-center">
      ARE YOU SURE YOU WANT TO LOGOUT ?
    </h5>
    <div className="d-flex justify-content-between">
      <button
        type="button"
        className="backbtn"
        onClick={() => setLogval(!logval)}
      >
        BACK
      </button>
      <button
        type="button"
        className="logoutbtn"
        onClick={() => signout()}
      >
        LOGOUT
      </button>
    </div>
  </PopupModal>
)}
          <div className="d-flex mt-2 justify-content-between maindiv" >
            <h1 className="text-uppercase mt-2 ms-3 plachetitle">
              {location?.state?.data?.project_title}
            </h1>
            <div>
              {isAuth().id === location?.state?.data?.user_id ? (
                <button
                  type="button"
                  className={
                    projectData.callfordata_enabled === "Y" ? "publicbtnActive " : "publicbtn"
                  }
                  style={{
                    backgroundColor: projectData.callfordata_enabled === "Y" ? "rgba(32, 159, 140, 0.05)" : "transparent",
                    border: projectData.callfordata_enabled === "Y" ? "1px solid rgba(32, 159, 140, 1)" : "1px solid rgba(0, 0, 0, 0.54)",
                    width:"200px",
                    borderRadius:"25px",
                    marginRight:"10px",
                    color:projectData.callfordata_enabled === "Y" ? "rgba(32, 159, 140, 1)" : "(0, 0, 0, 0.7)",
                    height:"40px"
                  }}
                  onClick={oncalldataOpen}
                >
                  +  DATA CALL
                </button>
              ) : null}

              <button type="button" className="publicbtn me-2 mt-2"
               style={{
                backgroundColor:"transparent",
                border: projectData.callfordata_enabled === "Y" ? "1px solid rgba(32, 159, 140, 1)" : "1px solid rgba(0, 0, 0, 0.54)",
                width:"200px",
                borderRadius:"25px",
                marginRight:"10px",
                color:"rgba(0, 0, 0, 0.7)",
                height:"40px"
              }}>
                {location?.state?.data?.project_privacy}
              </button>
            </div>
          </div>

          {/* Call For DATA View Here */}
          {!screencall ? null : (
            <CallForData
              uploadData={(states) => onSubmitData(states)}
              loadShareData={(data) => handleGenerateLink(data)}
              onClick={screencall}
              projDesc={projDesc}
              callenabled ={callenabled}
              unPublish = {(data) => unPublishCallForData(data)}
              deleteCall = {(data) => deleteCallForData(data)}
              parentStates={projectData}
              closeModal={(value) => {
                setglobal({ ...global, screencall: value });
              }}
            />
          )}
          {!screen7 ? (
            <p
              className="ms-3"
              onClick={() =>
                location?.state?.source === "expand"
                  ? navigate(
                    "/expand-project",
                    {
                      state: {
                        data: location?.state?.data,
                        source: "add_Project",
                      },
                    },
                    { replace: true }
                  )
                  : location?.state?.source === "expanddt"
                    ? navigate("/public-dataset", { replace: true })
                    : navigate(
                      "/expand-edit-project",
                      {
                        state: {
                          data: location?.state?.data,
                          source: "add_Project",
                        },
                      },
                      { replace: true }
                    )
              }
            >
              {location?.state?.data?.project_title} / DATABASE
            </p>
          ) : (
            <p className="ms-3">
              {location?.state?.data?.project_title} / DATABASE /{" "}
              {getFileNameFromUrl(fetched?.video)}
            </p>
          )}

          {!screen5 ? null : (
            <UploadData
              completeUploadData={(childStates) => completeUpload(childStates)}
              parentStates={values}
              projDesc={projDesc}
              pId={pId}
              projName={projName}
              isLoading={isUploadDataLoading}
              setFinalCount={finalCount}
              closeModal={(value) => {
                setglobal({ ...global, screen5: value });
              }}
              justifyContent="center"
            />
          )}
          {!screen7 ? null : (
            <div className="outercontainer">
              <div className="griddataContributor">
                <div>
                  <div className="d-flex  justify-content-between ">
                    <i
                      className="far fa-times-circle"
                      onClick={() =>
                        setglobal({
                          ...global,
                          screen7: !screen7,
                          editmode: false,
                        })
                      }
                    ></i>
                    {fetched.contributor === isAuth().username ? (
                      <img
                        alt="edit"
                        src={editpic}
                        height="20px"
                        width="20px"
                        onClick={() => setglobal({ ...global, editmode: true })}
                      />
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-lg-7 col-sm-7 col-xl-7">
                      {getFileNameFromUrl(fetched.video)
                        .match(/\.(png|jpg|jpeg)$/) ? (
                        <img
                          src={fetched.video.includes(".mp3") ? iconMp3 : fetched.video}
                          alt="dataimg"
                          width="540px"
                          height="333px"
                          style={{ padding: "30px", paddingRight: "0px" }}
                        />
                      ) : getFileNameFromUrl(fetched.video)
                      .match(/\.(stl|obj|usdz)$/) ?(
                      <ThreeDViewer/> ): (
                        <ReactPlayer
                          url={fetched.video}
                          light={<img src={getFileIcon(fetched.video)} height={"300px"}></img>}
                          controls
                          width="571px"
                          height="353px"
                          style={{ padding: "30px", paddingRight: "0px" }}
                          playing
                        />
                      )}
                      <p
                        style={{
                          paddingLeft: "30px",
                          fontSize: "13px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                        }}
                      >
                        {" "}
                        {changeFileExtension(getFileNameFromUrl(fetched.video))}
                      </p>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-xl-5">
                      <div
                        className="container"
                        style={{
                          padding: "15px",
                          paddingLeft: "0px",
                          fontSize: "12px",
                          fontFamily: "DM Mono",
                          fontWeight: "400",
                          textAlign: "start",
                        }}
                      >
                        <div className="row">
                          <div
                            className="col"
                            style={{
                              padding: "10px",
                              textTransform: "uppercase",
                              textAlign: "start",
                            }}
                          >
                            Contributed by
                          </div>
                          <div
                            className="col"
                            style={{
                              padding: "10px",
                              fontSize: "13px",
                              fontFamily: "Roboto",
                              textAlign: "start",
                            }}
                          >
                            <img
                              alt="download"
                              src={bulletpic}
                              height="20px"
                              width="20px"
                            />
                            &nbsp;
                            {location?.state?.data?.project_title ===
                              "ART SEARCH"
                              ? "N/A"
                              : fetched.contributor}
                          </div>
                          <div className="w-100"></div>
                          <div
                            className="col"
                            style={{ padding: "10px", textAlign: "start" }}
                          >
                            AUTHOR
                          </div>
                          <div
                            className="col"
                            style={{
                              padding: "10px",
                              fontSize: "13px",
                              textAlign: "start",
                            }}
                          >
                            <input
                              type="text"
                              disabled={!editmode}
                              className="griddataText"
                              value={
                                location?.state?.data?.project_title ===
                                  "ART SEARCH"
                                  ? fetched.authors
                                  : fetched.author
                              }
                              onChange={(e) => handleChangeauthor(e)}
                              style={{
                                backgroundColor: "transparent",
                                outline: "none",
                                border: "none",
                                marginBottom: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          padding: "0px",
                          paddingLeft: "0px",
                          fontSize: "12px",
                          fontFamily: "DM Mono",
                          fontWeight: "400",
                          textAlign: "start",
                         
                        }}
                      >
                        <p className="mb-1 griddataText">WHERE DID THIS COME FROM</p>
                        <div className="lineseperator2"></div>
                        <input
                          type="text"
                          disabled={!editmode}
                          className="griddataText"
                          value={
                            location?.state?.data?.project_title ===
                              "ART SEARCH"
                              ? fetched.whereComeFrom
                              : fetched.q1
                          }
                          onChange={(e) => handleChangeform(e)}
                          style={{
                            backgroundColor: "transparent",
                            outline: "none",
                            border: "none",
                            marginBottom: "10px",
                          }}
                        />

                        <br></br>
                        <br></br>
                        <p className="mb-1 griddataText">
                          HOW WAS THIS SOURCED
                        </p>
                        <div className="lineseperator2"></div>

                        <input
                          type="text"
                          disabled={!editmode}
                          className="griddataText"
                          value={fetched.q2}
                          onChange={(e) => handleChangeform2(e)}
                          style={{
                            backgroundColor: "transparent",
                            outline: "none",
                            border: "none",
                            marginBottom: "10px",
                          }}
                        />
                        <br></br>
                        <br></br>
                      </div>
                      {fetched.location &&
                       <div
                       style={{
                           fontSize: "12px",
                           fontFamily: "DM Mono",
                           fontWeight: "400",
                           textAlign: "start",
                         }}>
                          <p className="mb-1 griddataText">Location</p>
                          <div className="lineseperator2"></div>
                         <LocationDataDisplay data={fetched.location}/>
                       </div>
                      }
                     
                    </div>
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Table View */}
          <div className="projectdb mt-3" style={{padding:"0px 10px",background:"transparent",border:"1px solid rgba(0, 0, 0, 0.54)"}}>
            <div className="row p-3">
              <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="search"
                  className="artsearchinput"
                  placeholder="SEARCH"
                  style={inputStyles}
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                ></input>
              </div>
              <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                <select
                  onChange={handleChange}
                  name="FILETYPE "
                  className="artsearchinput"
                  style={dropinputStyles}
                >
                  <option key={0} value="">
                    {" "}
                    FILETYPE
                  </option>
                  {extensions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                <select
                  onChange={handleAction}
                  name="ACTION "
                  className="artsearchinput"
                  style={dropinputStyles}
                >
                  <option key={0} value="">
                    {" "}
                    ACTION
                  </option>
                  <option key={1} value="Download">
                    {" "}
                    Download
                  </option>
                  <option key={2} value="Delete">
                    {" "}
                    Delete
                  </option>
                </select>
              </div>
              {/* <div className="col-md-2 col-lg-2 col-xl-2 customdp">
                <div className="dropdown" >
                  <button className="dropdown-toggle actioninput" data-bs-toggle="dropdown" aria-expanded="false">
                    ACTION
                  </button>
                  <ul className="dropdown-menu ">
                    <li onClick={downloadItems1}><button className="dropdown-item " type="button"><img alt="download"
                      src={downloadpic} height="20px" width="20px"
                    />&nbsp; Download</button></li>
                    <br></br>
                    <li onClick={deleteItems}><button className="dropdown-item" type="button"><img alt="download"
                      src={deletepic} height="20px" width="20px"
                    />&nbsp; Delete</button></li>
                  </ul>
                </div></div> */}

              <div className="col-md-2 col-lg-2 col-xl-2">
                <p className="txtpagination">items per page: 10</p>
              </div>
              <div className="  col-md-2 col-lg-2 col-xl-2">
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  lastrecord={indexOfLastRecord}
                  total={datacontributions?.data?.length}
                />
              </div>
            </div>
            <table className="table">
              <thead>
                <tr
                  style={{
                    fontSize: "13px",
                    color: " rgba(78, 78, 78, 0.7)",
                    textTransform: "lowercase",
                  }}
                >
                  <th>#</th>
                  <th>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={MasterChecked}
                      id="mastercheck"
                      onChange={(e) => onMasterCheck(e)}
                      style={{height:"20px",width:"20px",border:"1px solid rgba(0, 0, 0, 0.74)"}}
                    />
                  </th>
                  <th>icon</th>
                  <th>file name</th>
                  {location?.state?.data?.project_title === "ART SEARCH" ? (
                    <>
                      <th>archive</th>
                      <th>collection</th>
                    </>
                  ) : (
                    <>
                      <th>author(s)</th>
                      <th>contributor</th>
                    </>
                  )}
                </tr>
              </thead>
              {loading && datacontributions?.data?.length > 0 && (
                <tbody>
                  {search(datacontributions?.data)
                    .slice(indexOfFirstRecord, indexOfLastRecord)
                    ?.map((info, key = 0) => (
                      <tr
                        key={key + 1}
                        onClick={() => {
                          setglobal({
                            ...global,
                            screen7: true,
                            fetched: info,
                          });
                        }}
                        style={{backgroundColor:"rgba(32, 159, 140, 0.05)",borderBottom:"1px solid rgba(0, 0, 0, 0.3)"}}
                      >
                        <td style={{ fontSize: "13px" }}>{key + 1}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={info?.selected || false}
                            className="form-check-input"
                            onChange={(e) => onItemCheck(e, info)}
                            style={{height:"20px",width:"20px",border:"1px solid rgba(0, 0, 0, 0.74)"}}
                          />
                        </td>
                        <td>
                          {" "}
                          {location?.state?.data?.project_title ===
                            "ART SEARCH" ? (
                            <ReactPlayer
                              url={info?.video}
                              width="70px"
                              height="30px"
                            />
                          ) : (
                            <img
                            src={getFileIcon(info?.video)}
                              style={imageStyle}
                              alt="img"
                            />
                          )}
                        </td>
                        <td style={{ fontSize: "13px" }}>
                          {changeFileExtension(getFileNameFromUrl(info?.video))}
                        </td>
                        {location?.state?.data?.project_title ===
                          "ART SEARCH" ? (
                          <>
                            <td style={{ fontSize: "13px" }}>{info.archive}</td>
                            <td style={{ fontSize: "13px" }}>
                              {info.collection}
                            </td>
                          </>
                        ) : (
                          <>
                            <td style={{ fontSize: "13px" }}>{info.author}</td>
                            <td style={{ fontSize: "13px",display:"flex",justifyContent:"center",border:"none",margin:"0"}}>
                              <div  className="customloggeduser"  style={{
                backgroundColor:"rgba(32, 159, 140, 1)",
              padding:"6px 10px",
              borderRadius:"20px",
              cursor:"pointer",
              fontSize:"14px",
              color:"white",
              width:"max-content",
              textTransform:"uppercase"

            }}>
                              <div className="d-flex align-items-center text-hover" >
               
               <img src={Ellipse} alt="elipse" style={{marginRight:"5px"}}/>
               {info.contributor}
             </div>
                              </div>
                           
                             
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
            {datacontributions?.data?.length === 0 ? (
              <p className="text-center pb-3" style={{ fontWeight: "bold" }}>
                <i className="fas fa-empty-set"></i> No Data Found
              </p>
            ) : !loading ? (
              <div style={spinCenter}>
                <div
                  className="spinner-border"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                ></div>
              </div>
            ) : null}
          </div>
         <div
         style={{display:"flex",
          justifyContent:"center",
          alignItems:"center",
          margin:"20px",
          height:"200px"
         }}>
         <button
            style={buttonStyles}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => setglobal({ ...global, screen5: true, image: "" })}
          >
            <p style={{ color: "rgba(255, 255, 255, 0.7)",margin:"0" }}>
              UPLOAD
            </p>
          </button>
         </div>
        
         
         
        </div>
      )}
    </>
  );
};

export default Projectcontributions;