import React, { useEffect, useRef, useState } from "react";
import { PopupModal } from "../DataPrivacy/PopupModal";
import { TablePopupModal } from "../DataPrivacy/TablePopupModal";
import { SelectedRowModal } from "../DataPrivacy/SelectedRowModal";
import Webcam from "react-webcam";
import services from "../../API_SERVICES/services";
import ReactPlayer from "react-player";
import path from "path";
import DataComponent from "./dataViewComponent";
import axios from "axios";
import Pagination from "../../Components/Pagination";
import Ellipse from "../../Assets/Images/Ellipse.png";
import Cross from "../../Assets/Images/crossicon.png";

const ModelExecute = () => {
  // const itemArray = [
  //   { name: "DISCOVER", value: "/decode" },
  //   { name: "FRAMES", value: "/CreateFrames" },
  //   { name: "BLUEPRINT", value: "/Howinputtab" },
  //   { name: "PROTOTYPE", value: "/modelspage" },
  //   { name: "REFLECTION", value: "/reflection" },
  // ];
  const videoConstraints = {
    width: 410,
    height: 260,
    facingMode: "user",
  };
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  const [active, setActive] = useState(false);
  const [dataset, setDataset] = useState("");
  const [items, setItems] = useState([]);
  const [file, setFile] = useState("");
  const [inputImage, setInputImage] = useState("");
  const [results, setResults] = useState(false);
  const [selectedVideos, setSelectedVideos] = React.useState([])
  const [projectData, setProjectData] = React.useState([]);
  const [fileExtensionList, setFileExtensionList] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState('');
  const [modelData, setmodelData] = useState({
    video_path: [],
    image_string: "",
    activate: false,
    activateTable: false,
    activateProfile: false,
    dataViewer: false,
    uploaded_img: false,
    temp: "",
    frame_count: "",
    output: "",
    loading: false,
  });
  let projectName = sessionStorage.getItem("projectName");
  const webcamRef = useRef(null);
  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  // No of Records to be displayed on each page
  // const [recordsPerPage, setRecordsPerPage] = useState(5);
  const recordsPerPage = 5;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages =
    dataset?.data?.length > 0
      ? Math.ceil(dataset?.data.length / recordsPerPage)
      : 1;
  const [selectedRowData, setSelectedRowData] = useState(null);


  const getS3Objects = React.useCallback(() => {
    const apiname = "getObjectS3";
    services
      .getS3Data(apiname)
      .then((response) => {
        if (projectName === "ART SEARCH") {
          getArtData(response);
        } else {
          setDataset(response.data);
        }
      })
      .catch((err) => {
        console.log("Error ===> ", err);
      });
  },[projectName]);

  const getArtData = (data) => {
    const apiname = "load-videos";
    services
      .getS3Data(apiname)
      .then((response) => {
        const tempData = [];
        response.data.videos.forEach((item, index) => {
          let tempObj = {
            Key: item.s3_url.split("/").pop(),
            url: item.s3_url,
            archive: item.archive,
            collection: item.collection,
            authors: item.whoFoundThis,
            whereComeFrom: item.whereComeFrom,
            ETag: index,
          }
          tempData.push(tempObj)
        });
        const tempJson = {
          data: {
            message: "Data fetched successfully",
            data: [...data.data.data, ...tempData]
          }
        }
        setDataset(tempJson.data);
      })
      .catch((err) => {
        console.log("Error ===> ", err);
      });
  };

  const capture = () => {
    const capturedImage = webcamRef.current.getScreenshot();
    setInputImage(capturedImage);
    setmodelData({ ...modelData, image_string: capturedImage });
    setActive(false);
  };

  const inputStyles = {
    width: "100%",
    borderRadius: "20px",
    padding: "7px",
    border: "1px solid black",
  };

  const dropinputStyles = {
    width: "100%",
    padding: "5px",
    fontSize: "16px",
    lineHeight: "1",
    border: "1px solid black",
    borderRadius: "20px",
    // background: 'url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right ',
    background:
      "url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)no-repeat right",
    backgroundColor: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 20px top 0.4em",
    height: "37px",
    paddingLeft: "20px",
  };

  const imageStyle = {
    width: "30px",
    height: "30px",
  };

  const onFileChange = (e) => {
    e.preventDefault();
    // Convert file into base64 String
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result;
      setInputImage(base64);
      setmodelData({
        ...modelData,
        video_path: selectedVideos,
        temp: URL.createObjectURL(e.target.files[0]),
        image_string: base64,
        uploaded_img: true,
        activate: false,
        activateTable: false,
      });
    };
    reader.readAsDataURL(e.target.files[0]);

  };

  const dataUpload = () => {
    // e.preventDefault();
    // setmodelData({
    //   ...modelData,
    //   video_path: selectedVideos,
    //   temp: URL.createObjectURL(file),
    //   uploaded_img: true,
    //   activate: false,
    //   activateTable: false,
    // });
  };

  const dataUpload2 = (e) => {
    e.preventDefault();
    setmodelData({
      ...modelData,
      video_path: selectedVideos,
      // temp: URL.createObjectURL(file),
      // uploaded_img: true,
      activate: false,
      activateTable: false,
    });
  };

  const checkhandler = (e, item) => {
    item.selected = e.target.checked;
    let tempList = dataset;
    tempList?.data.map((data, index) => {
      if (item.ETag === data.ETag) {
        data.selected = e.target.checked;
      }
      return data;
    });

    setDataset(tempList);
    // setselectedlist(dataset.data.filter((e) => e.selected))
    setSelectedVideos(tempList.data.filter((item) => item.selected === true).map(item => item.url));
    setItems(tempList.data.filter((item) => item.selected === true).map(item => item.url));
  }

  const {
    activate,
    activateTable,
    activateProfile,
    uploaded_img,
    temp,
    dataViewer,
    image_string,
    loading,
    frame_count,
    output,
  } = modelData;

  const ImageClear = () => {
    setmodelData({ ...modelData, uploaded_img: false, image_string: "" });
    setActive(false);
    setFile("");
  };

  
  const runModel = (e) => {
    e.preventDefault();
    setResults(true);
    const apiname = "run-model";
    const tempJSON = {
      video_path: items,
      image_path: file,
      image_string: image_string
    }
    // var formData = new FormData();
    // formData.append("video_path", JSON.stringify(items));
    // formData.append("arg2", file);
    // formData.append("image_string", image_string);
    setmodelData({ ...modelData, loading: true });
    services
      .post(apiname, tempJSON)
      .then((response) => {
        if (response.data.message === "unsuccessful") {
          setmodelData({
            ...modelData,
            frame_count: "",
            output: "",
            loading: false,
          });
        } else if (response.data.frame_count === "no matches found") {
          setmodelData({
            ...modelData,
            frame_count: "no matches found",
            output: response.data.video_url,
            loading: false,
          });
        } else {
          setmodelData({
            ...modelData,
            frame_count: response.data.frame_count,
            output: response.data.video_url,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log("Error is", err);
      });
  };

  const SetImageInModelData = () => {
    setFile(selectedImage);
    setmodelData({
      ...modelData,
      temp: selectedImage,
      uploaded_img: true,
      activate: false,
      activateTable: false,
      dataViewer: !dataViewer,
      activateProfile: false,
    })
  }

  const closeSelectedRowModal = () => {
    setSelectedRowData(null); // Reset selected row data
  };

  const getFileSizeFromS3Url = async (s3Url) => {
    return new Promise(async (res, rej) => {
      const response = await axios.head(s3Url);
      const fileSize = response.headers['content-length'];
      res((fileSize / 1024).toFixed(2).toString());
    });
  };

  // Call the api for Get the Project DATA 
  const loaddata = React.useCallback(() => {
    let apiname = "fetch-project-data";
    const project = true;
    services
      .get(apiname, project)
      .then((response) => {
        const tempArray = response.data.filter((item) => !item.video?.includes("dummyfileforTextUploadonly"));
        tempArray.forEach(async (item, index) => {
          tempArray[index].fileSize = await getFileSizeFromS3Url(item.video + "?not-from-cache-please");
        });
        setTimeout(() => {
          setProjectData(tempArray);
          setFileExtensionList(getExtensionList(response));
        }, 4000);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  },[]);

  useEffect(() => {
    getS3Objects();
    loaddata();
  }, [getS3Objects,loaddata]);

  const getExtensionList = (response) => {
    var ext = [];
    for (var i = 0; i < response?.data?.length; i++) {
      var filename = path.basename(response?.data[i]?.video);
      var extensions = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename).toString() : undefined
      if (!ext.includes(extensions) && extensions !== undefined) {
        ext.push(extensions);
      }
    }
    return ext;
  }

  const clearData = () => {
    setItems([]);
    let tempList = dataset;
    tempList?.data.map((data, index) => {
      data.selected = false;
      return data;
    });
    setDataset(tempList);
    setmodelData({ ...modelData, dataViewer: false });
    setSelectedVideos([]);
  }


  return (
    <>
      {!activate ? null : (
        <PopupModal>
          <div className="text-center b-none mb-3">
            <img
              src={Cross}
              alt="Close"
              style={imageStyle}
              onClick={() =>
                setmodelData({ ...modelData, activate: false })
              }
            />
            <h1>Upload Data</h1>
            <span>Required</span>
          </div>
          <div className="uploadinput text-center">
            <div className="d-flex flex-column">
              <label htmlFor="file" id="filelabel">
                <h1 className="mb-5">+</h1>
              </label>
              <input
                type="file"
                id="file"
                name="dataUpload"
                onChange={onFileChange}
                style={{ display: "none" }}
              />

              <span className="mt-4">
                Drop or choose a file (image, Video or audio)
              </span>
            </div>
          </div>
          <button type="button" className="Uplodbtn" onClick={dataUpload}>
            Upload
          </button>
        </PopupModal>
      )}
      {!activateTable ? null : (
        <TablePopupModal>
          <div className="projectdb mt-3">
            <img
              src={Cross}
              alt="Close"
              style={imageStyle}
              onClick={() =>
                setmodelData({ ...modelData, activateTable: false })
              }
            />
            <div className="row p-3">
              <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="search"
                  className="artsearchinput"
                  placeholder="SEARCH"
                  style={inputStyles}
                // value={q}
                // onChange={(e) => setQ(e.target.value)}
                ></input>
              </div>
              <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                <select
                  name="FILETYPE "
                  className="artsearchinput"
                  style={dropinputStyles}
                >
                  <option key={0} value="">
                    {" "}
                    FILETYPE
                  </option>
                  {/* {extensions.map((option, index) => (
                              <option key={index} value={option}>
                                  {option}
                              </option>
                          ))} */}
                </select>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2">
                <p className="txtpagination">items per page: 5</p>
              </div>
              <div className="  col-md-2 col-lg-2 col-xl-2">
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  lastrecord={indexOfLastRecord}
                  total={dataset?.data?.length}
                />
              </div>
            </div>

            <table className="table">
              <thead>
                <tr
                  style={{
                    fontSize: "13px",
                    color: " rgba(78, 78, 78, 0.7)",
                    textTransform: "lowercase",
                  }}
                >
                  <th>#</th>
                  <th>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      // checked={MasterChecked}
                      id="mastercheck"
                    // onChange={(e) => onMasterCheck(e)}
                    />
                  </th>
                  <th>icon</th>
                  <th className="text-start ">file name</th>
                  {projectName === "ART SEARCH" ?
                    <>
                      <th>archive</th>
                      <th>collection</th>
                    </>
                    : null}
                  {/* <th>author(s)</th>
                  <th>whereComeFrom</th>
                  <th>whoFoundThis</th> */}
                </tr>
              </thead>
              {/* {loading && dataset?.data?.length > 0 && ( */}
              {dataset?.data
                ?.slice(indexOfFirstRecord, indexOfLastRecord)
                .map((item, index) => (
                  <tbody key={index}>
                    {/* {search(dataset?.data).slice(indexOfFirstRecord, indexOfLastRecord)?.map((info, key = 0) => ( */}
                    <tr>
                      <td style={{ fontSize: "13px" }}>
                        {indexOfFirstRecord + index + 1}
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          // value={item.url}
                          checked={item?.selected || false}
                          className="form-check-input"
                          // onChange={checkhandler}
                          onChange={(e) => checkhandler(e, item)}
                        />
                      </td>
                      <td
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                        onClick={() => setSelectedRowData(item)}
                      >
                        <ReactPlayer
                          url={item.url}
                          width="70px"
                          height="30px"
                        />
                      </td>
                      <td
                        style={{ fontSize: "13px" }}
                        className="text-start "
                        onClick={() => setSelectedRowData(item)}
                      >
                        {item.Key}
                      </td>
                      {projectName === "ART SEARCH" ?
                        <td
                          style={{ fontSize: "13px" }}
                          className="text-start "
                          onClick={() => setSelectedRowData(item)}
                        >
                          {item.archive}
                        </td>
                        : null}
                      {projectName === "ART SEARCH" ?
                        <td
                          style={{ fontSize: "13px" }}
                          className="text-start "
                          onClick={() => setSelectedRowData(item)}
                        >
                          {item.collection}
                        </td>
                        : null}
                    </tr>
                    {/* ))} */}
                  </tbody>
                ))}
              {/* )} */}
            </table>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                type="button"
                onClick={dataUpload2}
                className={
                  (items.length > 0 && file !== "") ||
                    (items.length > 0 && image_string !== "")
                    ? "blkactivebtn ms-3 mb-3"
                    : "blkbtn ms-3 mb-3"
                }
                // disabled={
                //   (items.length > 0 && file !== "") ||
                //   (items.length > 0 && image_string !== "")
                //     ? false
                //     : true
                // }
                style={{ width: "20%" }}
              >
                SELECT
              </button>
            </div>
            {/* {dataset?.data?.length === 0 ? (
                  <p className="text-center pb-3" style={{ fontWeight: "bold" }}>
                      <i className="fas fa-empty-set"></i> No Data Found
                  </p>
              ) : !loading ? (
                  <div style={spinCenter}>
                      <div
                          className="spinner-border"
                          style={{ width: "3rem", height: "3rem" }}
                          role="status"
                      ></div>
                  </div>
              ) : null} */}
          </div>
        </TablePopupModal>
      )}
      {selectedRowData && (
        <SelectedRowModal>
          <div className="selectedRowContainer">
            <img
              src={Cross}
              alt="close"
              style={imageStyle}
              onClick={closeSelectedRowModal}
            />
            <div className="SelectedDataContainer">
              <div style={{ height: "100%", width: "35%", marginTop: "20px" }}>
                <ReactPlayer
                  url={selectedRowData.url}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
              <div style={{ width: "60%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "20%",
                    width: "60%",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                  }}
                >
                  <label style={{ marginRight: "10px", fontWeight: "bold" }}>
                    CONTRIBUTED BY
                  </label>
                  <lable style={{ fontWeight: "bold" }}>
                    <img src={Ellipse} alt="icon" />{"N/A"}
                  </lable>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "20%",
                    width: "55%",
                    justifyContent: "space-between",
                    margin: "0px 10px",
                  }}
                >
                  <label style={{ marginRight: "10px", fontWeight: "bold" }}>
                    AUTHOR
                  </label>
                  <lable style={{ fontWeight: "bold" }}>{selectedRowData.authors}</lable>
                </div>
                <div
                  style={{
                    margin: "20px 0px 10px 10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    lineHeight: "17px",
                    fontWeight: "bold",
                  }}
                >
                  <p>WHERE DID THIS COME FROM</p>
                  <label
                    style={{ borderTop: "1px solid black", height: "20px" }}
                  >
                    {projectName === "ART SEARCH" ? selectedRowData?.whereComeFrom :
                      "DEEPFACE MODEL"
                    }
                  </label>
                </div>
                <div
                  style={{
                    margin: "20px 0px 10px 10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    lineHeight: "17px",
                    fontWeight: "bold",
                  }}
                >
                  <p>HOW WAS THIS SOURCED</p>
                  <label
                    style={{ borderTop: "1px solid black", height: "20px" }}
                  >
                    POIETO
                  </label>
                </div>
              </div>
            </div>
            <div
              className="SelectedFileName"
              style={{ margin: "10px 0px 0px 0px" }}
            >
              <label>{selectedRowData.Key}</label>
            </div>
          </div>
        </SelectedRowModal>
      )}
      {!activateProfile ? null : (
        <TablePopupModal>
          <DataComponent
            Data={projectData}
            deepFace={true}
            selectImage={(item) => setSelectedImage(item)}
            ExtensionList={fileExtensionList}
            UploadButton={false}
            fetchData={(value) => value ? loaddata() : null}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              type="button"
              onClick={SetImageInModelData}
              className={
                selectedImage
                  ? "blkactivebtn ms-3"
                  : "blkbtn ms-3"
              }
              disabled={!selectedImage
                ? true
                : false
              }
              style={{ width: "20%" }}
            >
              SELECT
            </button>
          </div>
        </TablePopupModal>
      )}

      <div
        className="row justify-content-around ps-3 "
        style={{
          width: "100%",
          marginBottom: "50px",
        }}
      >
        {/* <div className="horizontal-seperator">
          <h4 className="text-uppercase" style={{ fontSize: "20px" }}>
            demo
          </h4>
        </div> */}
        <p style={{ fontSize: "20px" }}>{projectName} / MODELS / DEEPFACE / DEMO</p>
        <div>
          <h4 className="text-uppercase" style={{ fontSize: "30px" }}>
            DEEPFACE
          </h4>
          <h6 style={{ fontSize: "20px" }}>
            A face classification model. Upload a profile picture and search for matches in selected videos.
          </h6>
        </div>
        {results ? (
          <div className="row mt-3">
            <div className="col resultContainer ps-2 pe-2 me-1">
              <h4 className="card-title">
                <span className="no">1</span>Profile Picture
              </h4>
              {active === false && uploaded_img === false && !image_string ? (
                <div
                  className="blkbox"
                  style={{
                    height: "160px",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="iconOuter">
                    <div className="col-lg-3 col-md-3 col-3">
                      <span className="iconContainer">
                        <input
                          type="file"
                          id="file"
                          name="dataUpload"
                          onChange={onFileChange}
                          style={{ display: "none" }}
                        />
                        {/* <label htmlFor="file">
                          <h1 className="mb-5">Upload</h1>
                        </label> */}
                        <label for="file">
                          <i
                            className="fa-solid fa-upload"
                          // onClick={() =>
                          //   setmodelData({ ...modelData, activate: true })
                          // }
                          ></i>
                        </label>
                      </span>{" "}
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      {" "}
                      <span
                        className="iconCamera"
                        onClick={() => {
                          setmodelData({
                            ...modelData,
                            dataViewer: !dataViewer,
                            activateProfile: true,
                          })
                        }}
                      >
                        <i
                          className="fa-solid fa-folder"
                          style={{ color: "#C7CCB2" }}
                        ></i>
                      </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-3">
                      {" "}
                      <span
                        className="iconCamera"
                        onClick={() => setActive(!active)}
                      >
                        <i className="fa-solid fa-camera"></i>
                      </span>
                    </div>
                  </div>
                </div>
              ) : uploaded_img === true && active === false ? (
                <div className="imageBox2">
                  <img
                    src={temp}
                    alt=""
                    // width="25%"
                    height="100%"
                    style={{ display: "block", margin: "auto" }}
                  />
                </div>
              ) : active === true && uploaded_img === false ? (
                <div style={{ marginTop: "15px", position: "relative" }}>
                  <i
                    className="fas fa-times cross-camera"
                    onClick={() => setActive(false)}
                  ></i>
                  <Webcam
                    audio={false}
                    screenshotFormat="image/jpeg"
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                  />
                  <button className="camerabtn" onClick={capture}>
                    Capture
                  </button>
                </div>
              ) : image_string && active === false ? (
                <div className="imageBox2">
                  <img
                    src={inputImage}
                    alt=""
                    // width="80%"
                    height="100%"
                    style={{ display: "block", margin: "auto" }}
                  />
                </div>
              ) : (
                <div className="imageBox2">
                  <img
                    src={inputImage}
                    alt=""
                    // width="100px"
                    height="100%"
                    style={{ display: "block", margin: "auto" }}
                  />
                </div>
              )}
              <h4 className="card-title mt-3">
                <span className="no">2</span>Select Videos
              </h4>
              <div className="bllkbox2">
                {selectedVideos.map((video, index) => (
                  <div key={index} style={{ display: "flex", border: "1px solid grey", margin: "10px", height: "90px", objectFit: "contain" }}>
                    <div style={{ borderRadius: "10px", height: "100%", width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <ReactPlayer
                        url={video}
                        controls
                        height="100%"
                        width="100%" />
                    </div>
                    <p style={{ display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center", marginLeft: "20px" }}>{video.slice(60, 85)}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col resultContainer ps-2 pe-2 ms-1">
              <div className="stylecard" style={{ position: "relative" }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title">
                      <span className="no">3</span>VIDEO
                    </h4>
                  </div>
                  {loading === false && frame_count && output && (
                    <>
                      {frame_count === "no matches found" ? (
                        <p className="ms-5 mt-1">No matches found</p>
                      ) : (
                        <p className="ms-5 mt-1">{frame_count} frames</p>
                      )}
                      <ReactPlayer
                        url={output}
                        controls
                        width="99%"
                        height="80%"
                        playing
                      />
                    </>
                  )}
                  {loading === true && (
                    <div style={spinCenter}>
                      <div
                        className="spinner-border"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <button className="blkbtn mt-3" onClick={() => window.location.reload()}>Refresh</button>
          </div>
        ) : (
          <div className="">
            {/* <p
            className="d-flex justify-content-end me-3"
            onClick={() => window.location.reload()}
          >
            (REFRESH)
          </p> */}
            <div className="stylecard mt-3">
              <div
                className="card-body"
                style={{
                  border: "1px solid #4E4E4E",
                  borderRadius: "10px",
                  width: "50%",
                  marginRight: "10px",
                }}
              >
                <h4 className="card-title">
                  <span className="no">1</span>Profile Picture
                </h4>
                <span className="ms-4">Image input - provide a profile picture.</span>
                {active === false && uploaded_img === false && !image_string ? (
                  <div
                    className="blkbox"
                    style={{
                      height: "300px",
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="iconOuter">
                      <div className="col-lg-3 col-md-3 col-3">
                        <span className="iconContainer">
                          <input
                            type="file"
                            id="file"
                            name="dataUpload"
                            onChange={onFileChange}
                            style={{ display: "none" }}
                          />
                          <label for="file">
                            <i
                              className="fa-solid fa-upload"
                              style={{ fontSize: "28px" }}
                            // onClick={() =>
                            //   setmodelData({ ...modelData, activate: true })
                            // }
                            ></i>
                          </label>
                        </span>{" "}
                      </div>
                      <div className="col-lg-3 col-md-3 col-3">
                        {" "}
                        <span
                          className="iconCamera"
                          onClick={() =>
                            setmodelData({
                              ...modelData,
                              dataViewer: !dataViewer,
                              activateProfile: true,
                            })
                          }
                        >
                          <i
                            className="fa-solid fa-folder"
                            style={{ color: "#C7CCB2", fontSize: "30px" }}
                          ></i>
                        </span>
                      </div>
                      <div className="col-lg-3 col-md-3 col-3">
                        {" "}
                        <span
                          className="iconCamera"
                          onClick={() => setActive(!active)}
                        >
                          <i className="fa-solid fa-camera"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : uploaded_img === true && active === false ? (
                  <div className="imageBox">
                    <img
                      src={temp}
                      alt=""
                      // width="25%"
                      height="100%"
                      style={{ display: "block", margin: "auto" }}
                    />
                  </div>
                ) : active === true && uploaded_img === false ? (
                  <div style={{ marginTop: "15px", position: "relative" }}>
                    <i
                      className="fas fa-times cross-camera"
                      onClick={() => setActive(false)}
                    ></i>
                    <div className="d-flex justify-content-center align-items-center">
                      <Webcam
                        audio={false}
                        screenshotFormat="image/jpeg"
                        ref={webcamRef}
                        videoConstraints={videoConstraints}
                      />
                    </div>
                    <button className="camerabtn mt-3" onClick={capture}>
                      Capture
                    </button>
                  </div>
                ) : image_string && active === false ? (
                  <div className="imageBox">
                    <img
                      src={image_string}
                      alt=""
                      width="80%"
                      height="100%"
                      style={{ display: "block", margin: "auto" }}
                    />
                  </div>
                ) : null}

                <p className="d-flex justify-content-end" onClick={ImageClear}>
                  CLEAR
                </p>
              </div>
              <div
                className="card-body"
                style={{
                  border: "1px solid #4E4E4E",
                  borderRadius: "10px",
                  width: "50%",
                }}
              >
                <h4 className="card-title">
                  <span className="no">2</span>Select Videos
                </h4>
                <p className="ms-4">Video Input - select videos</p>
                {!dataViewer ? (
                  <div
                    className="blkbox"
                    style={{ height: "300px", width: "100%" }}
                  >
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <i
                        className="fa-solid fa-folder"
                        style={{ color: "#C7CCB2", fontSize: "30px" }}
                        onClick={() =>
                          setmodelData({
                            ...modelData,
                            dataViewer: !dataViewer,
                            activateTable: true,
                          })
                        }
                      ></i>
                    </div>
                  </div>
                ) : (
                  <div className="bllkbox">
                    {selectedVideos.map((video, index) => (
                      <div key={index} style={{ display: "flex", border: "1px solid grey", margin: "10px", height: "90px", objectFit: "contain" }}>
                        <div style={{ height: "100%", width: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <ReactPlayer
                            url={video}
                            controls
                            height="100%"
                            width="100%" />
                        </div>
                        <p style={{ display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center", marginLeft: "20px" }}>{video.slice(60, 85)}</p>
                      </div>
                    ))}
                  </div>
                )}
                <p
                  className="d-flex justify-content-end"
                  onClick={() =>
                    clearData()
                  }
                >
                  CLEAR
                </p>
              </div>
            </div>
            <center style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
              <button
                type="button"
                onClick={runModel}
                className={
                  (items.length > 0 && file !== "") ||
                    (items.length > 0 && image_string !== "")
                    ? "blkactivebtn ms-1"
                    : "blkbtn ms-1"
                }
                disabled={
                  (items.length > 0 && file !== "") ||
                    (items.length > 0 && image_string !== "")
                    ? false
                    : true
                }
              >
                RUN MODEL
              </button>
            </center>
          </div>
        )}
      </div>
    </>
  );
};

export default ModelExecute;
