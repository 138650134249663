import React, { useState } from "react";
import services from "../../API_SERVICES/services";
import toast from "react-hot-toast";
import Pagination from "../../Components/Pagination";
import iconMp3 from '../../Assets/Images/mp3_icon.png'
import path from "path";
import moment from "moment";
import debounce from "lodash.debounce";
import { isAuth, isId } from "../../Storage(Methods)/storage";
import editpic from "../../Assets/Images/edit.png";
import bulletpic from "../../Assets/Images/Ellipse.png";
import ReactPlayer from "react-player";
import { PopupModal } from "../DataPrivacy/PopupModal";
import UploadData from "../../Components/ProjectDBSEComponents/UploadData";

const DataComponent = (props) => {
    const [q, setQ] = useState("");
    const video = JSON.parse(sessionStorage.getItem("data"));
    const [isHovered, setIsHovered] = useState(false);
    const pId = sessionStorage.getItem("identifier");
    const [isUploadDataLoading, setIsUploadDataLoading] = React.useState(false);
    const [state, setState] = React.useState({
        dataContributions: '',
        extensions: [],
        MasterChecked: false,
        loading: false,
        screen7: false,
        editMode: false,
        fetched: '',
        screen5: false,
        image: '',
        screen6: false,
    });

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const [selectedList, setSelectedList] = useState([]);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const nPages = props.Data?.length > 0 ? Math.ceil(props.Data?.length / recordsPerPage) : 1;
    const pertData =props.perticularProject;
    const [filterParam, setFilterParam] = useState(['']);
    // const [searchParam] = useState(["video", "author"]);
    const [details, setDetails] = useState({
        author: "",
        q1: "",
        q2: "",
        permissions1: "",
        permissions2: "",
        permissions3: "",
    });

    // OnChange the FILE TYPE
    const handleChange = (e) => {
        setFilterParam(e.target.value);
    };

    const handleAction = (e) => {
        if (e.target.value === "Download") {
            downloadItems1();
        } else if (e.target.value === "Delete") {
            deleteItems();
        }
    }

    // ACTIONS dropdown option function
    const downloadItems1 = () => {
        for (var i = 0; i < selectedList?.length; i++) {
            if (selectedList[i].video.match(/\.(png|jpg|jpeg)$/)) {
                fetch(selectedList[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "image.png"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (selectedList[i].video.match(/\.(mp3|aac)$/)) {
                fetch(selectedList[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "audio.mp3"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (selectedList[i].video.match(/\.(mp4)$/)) {
                fetch(selectedList[i].video, {
                    method: "GET",
                    cache: "no-cache"
                }).then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "video.mp4"); //or any other extension
                        link.click();
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }
    const deleteItems = () => {
        let deleteTemp = [];
        for (var i = 0; i < selectedList?.length; i++) {
            deleteTemp.push(selectedList[i].dcId);
        }
        const data = {
            dcId: deleteTemp
        };
        services
            .post("delete-data", data)
            .then((response) => {
                toast.success("Deleted successfully");
                props.fetchData(true)
            }).catch((err) => {
                console.log(err);
            });
    }

    const onMasterCheck = (e) => {
        // SelectedList: this.state.List.filter((e) => e.selected),
        let tempList = state.dataContributions;
        // Check/ UnCheck All Items
        tempList?.data.map((data) => (data.selected = e.target.checked));
        setState({
            ...state,
            dataContributions: tempList,

        });
        setSelectedList(state.dataContributions.data.filter((e) => e.selected))
    }

    // Set the file in states
    // const onFileChange = (e) => {
    //     setState({ ...state, image: e.target.files[0] });
    // };

    // Search function
    // function search(items) {
    //     return items.filter((item) => {
    //         if ((path.basename(item?.video)).includes(filterParam)) {
    //             return searchParam.some((newItem) => {
    //                 if (item[newItem] !== null) {
    //                     return (
    //                         item[newItem]
    //                             .toString()
    //                             .toLowerCase()
    //                             .indexOf(q.toLowerCase()) > -1
    //                     );
    //                 }
    //             });
    //         } else if (filterParam == "") {
    //             return searchParam.some((newItem) => {
    //                 return (
    //                     item[newItem]
    //                         .toString()
    //                         .toLowerCase()
    //                         .indexOf(q.toLowerCase()) > -1
    //                 );
    //             });
    //         }
    //     });
    // }

    // To select the row from the table
    const onItemCheck = (e, item) => {
        let tempList = props?.Data;
        tempList.map((data) => {
            if (data.dcId === item.dcId) {
                data.selected = e.target.checked;
            }
            return data;
        });
        setSelectedList(tempList.filter((item) => item.selected));
        if (props.deepFace) {
            props.selectImage(item.video);
        }
    }

    const DelayApiCall = React.useCallback(
        debounce(
            (apiname, data) =>
                services
                    .post(apiname, data)
                    .then((response) => {
                        toast.success(response.data.message);
                    })
                    .catch((error) => {
                        toast.error(error.response.data.error);
                    }),
            1500
        ),
        []);

    let handleChangeauthor = (e) => {
        let newfetched = state.fetched;
        newfetched.author = e.target.value;
        setState({ ...state, fetched: newfetched });
        const data = { id: state.fetched.dcId, q1: state.fetched.q1, q2: e.target.value, author: e.target.value }
        DelayApiCall("edit_dataset", data);
    }

    let handleChangeform = (e) => {
        let newfetched = state.fetched;
        newfetched.q1 = e.target.value;
        setState({ ...state, fetched: newfetched });
        const data = { id: state.fetched.dcId, q1: e.target.value, q2: state.fetched.q2, author: state.fetched.author }
        DelayApiCall("edit_dataset", data);
    }

    let handleChangeform2 = (e) => {
        let newfetched = state.fetched;
        newfetched.q2 = e.target.value;
        setState({ ...state, fetched: newfetched });
        const data = { id: state.fetched.dcId, q1: state.fetched.q1, q2: e.target.value, author: state.fetched.author }
        DelayApiCall("edit_dataset", data);
    }

    const dataUpload = (childStates) => {
        setIsUploadDataLoading(true);
        const apiname = "data_upload";
        var formData = new FormData();
        formData.append("dataUpload", childStates.image);
        formData.append("pId", isId());
        formData.append("user_id", isAuth().id);
        formData.append("contributor", isAuth().username);
        services
            .post(apiname, formData)
            .then((response) => {
                toast.success(response.data.message);
                sessionStorage.setItem(
                    "data",
                    JSON.stringify(response?.data?.data?.video)
                );
                sessionStorage.setItem(
                    "id",
                    JSON.stringify(response?.data?.data?.dcId)
                );
                setState({
                    ...state,
                    screen5: true,
                });
                completeUpload(childStates, response?.data?.data?.dcId)
            })
            .catch((err) => {
                setIsUploadDataLoading(false);
                toast.error(err.response.data.error);
                setState({
                    ...state,
                    image: ''
                });
            });
    };

    const checkhandler = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
    };

    const completeUpload = (childStates, id) => {
        const apiname = "data-details";
        var per = "";
        if (childStates.permissionOne !== '') {
            per = per + childStates.permissionOne + ";";
        }
        if (childStates.permissionTwo !== '') {
            per = per + childStates.permissionTwo + ";";
        }
        if (childStates.permissionThree !== '') {
            per = per + childStates.permissionThree;
        }
        const data = {
            // author: details.author,
            // q1: details.q1,
            // q2: details.q2,
            // permissions: per,
            // id: dataId,
            author: childStates.author,
            q1: childStates.whereDidThisComeFrom ? childStates.whereDidThisComeFrom : "NA",
            q2: childStates.howWasThisSourced,
            permissions: per,
            id: id,
            call_for_data_fields: JSON.stringify(childStates.dataFields),
            optional_question_data: `[]`,
            consent_optional_email: childStates.email,
            consent_donation_paid: childStates.donationOrPaid,
        };
        services
            .post(apiname, data)
            .then((response) => {
                toast.success(response.data.message);
                setDetails({ ...details, author: "", q1: "", q2: "", permissions1: "", permissions2: "", permissions3: "" });
                setState({ ...state, image: "", screen6: false });
                setIsUploadDataLoading(false);
            })
            .catch((err) => {
                setIsUploadDataLoading(false);
                toast.error(err.response.data.error);
            });
    };

    // Handle mouse enter and leave events to track hover state
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

 

  const getFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const filename = urlObject.pathname.split('/').pop();
    return filename;
};

const changeFileExtension = (url) => {
    if (url.endsWith('.png') && url.startsWith('drawing-prompt')) {
      return url.replace('.png', '.svg');
    }
    return url;
  }
  

    const buttonStyles = {
        padding: "20px",
        borderRadius: "50px",
        width: "45%",
        display: "block",
        margin: "50px auto 0 auto",
        color: "rgba(255, 255, 255, 0.7)",
        border: "none",
        backgroundColor: isHovered ? "black" : "rgba(0, 0, 0, 0.54)",
        textAlign: "center",
    };

    return (
        <>
            {!state.screen7 ? null : (
                <div className="outercontainer">
                    <div className="griddataContributor">
                        <div >
                            <div className="d-flex  justify-content-between ">

                                <i
                                    className="far fa-times-circle hover-btn"
                                    onClick={() => setState({ ...state, screen7: !state.screen7, editMode: false })}
                                ></i>
                                {state.fetched.contributor === isAuth().username ? <img alt="edit"
                                    src={editpic} height="20px" width="20px" className="hover-btn"
                                    onClick={() => setState({ ...state, editMode: true })} /> : null}

                            </div>
                            <div className="row">
                                <div className="col-lg-7 col-sm-7 col-xl-7">
                                    {getFileNameFromUrl(state.fetched.video).match(/\.(png|jpg|jpeg)$/) ? (
                                        <img
                                            src={state.fetched.video}
                                            alt="dataimg"
                                            width="540px"
                                            height="333px"
                                            style={{ padding: "30px", paddingRight: "0px" }}
                                        />
                                    ) : (
                                        <ReactPlayer
                                            url={state.fetched.video}
                                            controls
                                            width="571px"
                                            height="353px"
                                            style={{ padding: "30px", paddingRight: "0px" }}
                                            playing
                                        />
                                    )}
                                    <p style={{ paddingLeft: "30px", fontSize: "13px", fontFamily: "Roboto", fontWeight: "400" }}
                                    >    {changeFileExtension(getFileNameFromUrl(state.fetched.video))}</p>
                                </div>
                                <div className="col-lg-5 col-sm-5 col-xl-5">

                                    <div className="container" style={{ padding: "30px", paddingLeft: "0px", fontSize: "12px", fontFamily: "DM Mono", fontWeight: "400", textAlign: "start" }}>
                                        <div className="row">
                                            <div className="col" style={{ padding: "10px", textTransform: "uppercase", textAlign: "start" }}>Contributed by</div>
                                            <div className="col" style={{ padding: "10px", fontSize: "13px", fontFamily: "Roboto", textAlign: "start" }}>
                                                <img alt="download"
                                                    src={bulletpic} height="20px" width="20px"
                                                />&nbsp;{state.fetched.contributor}</div>
                                            <div className="w-100"></div>
                                            <div className="col" style={{ padding: "10px", textAlign: "start" }}>AUTHOR</div>
                                            <div className="col" style={{ padding: "10px", fontSize: "13px", textAlign: "start" }}>
                                                <input type="text" disabled={!state.editMode}
                                                    className='griddataText'
                                                    value={state.fetched.author}
                                                    onChange={e => handleChangeauthor(e)}
                                                    style={{ backgroundColor: "transparent", outline: 'none', border: "none", marginBottom: "20px" }} />

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ padding: "30px", paddingLeft: "0px", fontSize: "12px", fontFamily: "DM Mono", fontWeight: "400", textAlign: "start" }}
                                    >

                                        <p className="griddataText">WHERE DID THIS COME FROM</p>
                                        <div className="lineseperator2"></div>

                                        <input type="text" disabled={!state.editMode}
                                            className='griddataText'
                                            value={state.fetched.q1}
                                            onChange={e => handleChangeform(e)}
                                            style={{ backgroundColor: "transparent", outline: 'none', border: "none", marginBottom: "20px" }} />

                                        <br></br>
                                        <br></br>
                                        <p className="mb-1 griddataText" >HOW WAS THIS SOURCED</p>
                                        <div className="lineseperator2"></div>

                                        <input type="text" disabled={!state.editMode}
                                            className='griddataText'
                                            value={state.fetched.q2}
                                            onChange={e => handleChangeform2(e)}
                                            style={{ backgroundColor: "transparent", outline: 'none', border: "none", marginBottom: "20px" }} />
                                    </div></div>
                                <br></br><br></br>
                            </div>
                        </div></div></div>
            )} 
            {!state.screen5 ? null : (
                <UploadData
                completeUploadData={(childStates) => dataUpload(childStates)}
                parentStates={pertData}
                pId={pId}
                isLoading={isUploadDataLoading}
                closeModal={(value) => {
                    setState({ ...state, screen5: value });
                  }}
                  justifyContent="center"
                />
              
            )}
            {!state.screen6 ? null : (
                <PopupModal>
                    {" "}
                    <div className="row">
                        <h4
                            className="text-center"
                            style={{ fontSize: "22px", color: "#0000008A" }}
                        >
                            CALL FOR DATA
                        </h4>
                        <h4
                            className="text-center"
                            style={{ fontSize: "18px", color: "#0000008A" }}
                        >
                            File upload successful
                        </h4>
                        {path.basename(video).match(/\.(png|jpg|jpeg)$/) ? (
                            <img
                                src={video}
                                alt="dataimg"
                                className="d-block m-auto"
                                style={{ width: "120px", height: "120px", borderRadius: "20px" }}
                            />
                        ) : (
                            <ReactPlayer
                                url={video}
                                className="d-block m-auto"
                                controls
                                width="30%"
                                height="55%"
                                playing
                            />
                        )}
                        <p className="text-center">
                            {state.image?.name}-{isAuth().fullname}
                        </p>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label style={{ fontSize: "12px" }}>AUTHOR</label>
                            <input
                                type="text"
                                className="dataContributeFormInput"
                                placeholder="Enter info..."
                                autoComplete="off"
                                name="author"
                                onChange={checkhandler}
                            />
                            <label style={{ fontSize: "12px", marginTop: "1rem" }}>
                                WHERE DID THIS COME FROM ?
                            </label>
                            <input
                                type="text"
                                className="dataContributeFormInput mb-3"
                                placeholder="Enter info..."
                                autoComplete="off"
                                name="q1"
                                onChange={checkhandler}
                            />
                            <label style={{ fontSize: "12px" }}>
                                HOW WAS THIS SOURCED ?
                            </label>
                            <input
                                type="text"
                                className="dataContributeFormInput"
                                placeholder="Enter info..."
                                autoComplete="off"
                                name="q2"
                                onChange={checkhandler}
                            />
                            <h6 className="mt-3 mb-1" style={{ fontSize: "12px", color: "#4E4E4EB2" }}>CONSENT AND PERMISSIONS</h6>
                            <p style={{ fontSize: "12px", color: "#4E4E4EB2" }}>
                                PLEASE SELECT ALL THAT APPLY.PLEASE SEND ANY QUESTIONS TO
                                DATA@POIETO.COM
                            </p>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="permissions1"
                                    onChange={checkhandler}
                                    value="I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE"
                                    style={{ marginTop: "0.8rem" }}

                                />
                                <label className="form-check-label" for="gridCheck1" style={{ fontSize: "12px", color: "#4E4E4EB2", marginTop: "0.8rem" }}>
                                    I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE
                                </label>
                            </div>
                            <div className="form-check mb-2 mt-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="permissions2"
                                    value=" I consent to this data being used according to the data
                        settings of this project, for as long as the project
                        chooses to store the data"
                                    onChange={checkhandler}
                                    style={{ marginTop: "0.8rem" }}

                                />
                                <label
                                    className="form-check-label"
                                    for="gridCheck1"
                                    style={{ textTransform: "uppercase", fontSize: "12px", color: "#4E4E4EB2", marginTop: "0.8rem" }}
                                >
                                    I consent to this data being used according to the data
                                    settings of this project, for as long as the project
                                    chooses to store the data (read about our data policy and
                                    future plans here)
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="permissions3"
                                    onChange={checkhandler}
                                    value=" Can we follow-up with you about your upload?"
                                    style={{ marginTop: "0.8rem" }}
                                />
                                <label
                                    className="form-check-label"
                                    for="gridCheck1"
                                    style={{ textTransform: "uppercase", fontSize: "12px", color: "#4E4E4EB2", marginTop: "0.8rem" }}
                                >
                                    Can we follow-up with you about your upload?
                                </label>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button type="button" className="backbtn">
                                    BACK
                                </button>
                                <button
                                    type="button"
                                    className="savebtn"
                                    onClick={completeUpload}
                                >
                                    COMPLETE UPLOAD
                                </button>
                            </div>
                        </div>
                    </div>
                </PopupModal>
            )}
            <div className="projectdb mt-3">
                <div className="row p-3">
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                        <input
                            type="search"
                            className="artsearchinput"
                            placeholder="SEARCH"
                            style={inputStyles}
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                        ></input>
                    </div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                        <select onChange={handleChange} name="FILETYPE " className="artsearchinput"
                            style={dropInputStyles}>
                            <option key={0} value=''> FILETYPE</option>
                            {props.ExtensionList.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 customdrop">
                        <select onChange={handleAction} name="ACTION " className="artsearchinput"
                            style={dropInputStyles}>
                            <option key={0} value=''> ACTION</option>
                            <option key={1} value="Download" > Download</option>
                            <option key={2} value="Delete"> Delete</option>
                        </select>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xl-2">
                        <p className="txtpagination">items per page: 10</p>
                    </div>
                    <div className="  col-md-2 col-lg-2 col-xl-2">
                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            lastrecord={indexOfLastRecord}
                            total={props.Data?.length}
                        />
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr style={{ fontSize: "13px", color: " rgba(78, 78, 78, 0.7)", textTransform: "lowercase" }}>
                            <th>#</th>
                            <th>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={state.MasterChecked}
                                    id="mastercheck"
                                    onChange={(e) => onMasterCheck(e)}
                                /></th>
                            <th >icon</th>
                            <th className="text-start">file name</th>
                            <th>author(s)</th>
                            <th>file size</th>
                            <th>date added</th>
                            <th>contributor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.Data.slice(indexOfFirstRecord, indexOfLastRecord).map((item, index) => (
                            <tr
                                key={index.toString()}
                            >
                                <td style={{ fontSize: "13px" }}>{index + 1}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={item?.selected || false}
                                        className="form-check-input"
                                        onChange={(e) => onItemCheck(e, item)}
                                    />
                                    </td>
                                <td
                                    onClick={() => {
                                        setState({ ...state, screen7: !state.screen7, fetched: item });
                                    }}
                                >
                                    {" "}
                                    <img
                                        src={item?.video.includes("mp3") ? iconMp3 : item?.video}
                                        style={imageStyle}
                                        alt="item.video"
                                    />
                                </td>
                                <td className="text-start" style={{ fontSize: "13px" }}>
                                    {changeFileExtension(getFileNameFromUrl(item?.video))}
                                </td>
                                <td style={{ fontSize: "13px" }}>{item.author}</td>
                                <td style={{ fontSize: "13px" }}>{item.fileSize} KB</td>
                                <td style={{ fontSize: "13px" }}>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                <td style={{ fontSize: "13px" }}>{item.contributor}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* {state.dataContributions?.data?.length === 0 ? (
                <p className="text-center pb-3" style={{ fontWeight: "bold" }}>
                    <i className="fas fa-empty-set"></i> No Data Found
                </p>
            ) : !loading ? (
                <div style={spinCenter}>
                    <div
                        className="spinner-border"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                    ></div>
                </div>
            ) : null} */}
            </div>
            {props.UploadButton ? (
                <button
                    style={buttonStyles}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setState({ ...state, screen5: true, image: "" })}
                >
                    <p className="m-0" style={{ color: "rgba(255, 255, 255, 0.7)" }}>UPLOAD</p>
                </button>
            ) : (
                <></>
            )}
        </>
    );
};


const inputStyles = {
    width: "100%",
    borderRadius: "20px",
    padding: "7px",
    border: "1px solid black",
};

const dropInputStyles = {
    width: "100%",
    padding: "5px",
    fontSize: "16px",
    lineHeight: "1",
    border: "1px solid black",
    borderRadius: "20px",
    // background: 'url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right ',
    background: 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+)no-repeat right',
    backgroundColor: "none",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "right 20px top 0.4em",
    height: "37px",
    paddingLeft: "20px"
};

const imageStyle = {
    width: "30px",
    height: "30px",
};

export default DataComponent;