import React from "react";

const LocationDataDisplay = ({ data }) => {
  const filteredData = Object.entries(data).filter(([key, value]) => value !== "N/A");

  return (
    <div style={styles.container}>
      {filteredData.map(([key, value], index) => (
        <div key={index} style={styles.row}>
          <div style={styles.cellKey}>
            <label style={{fontFamily:"DM Mono"}}>{key}</label>
          </div>
          <div style={styles.cellValue}>{value || "N/A"}</div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gap: "0px", // Spacing between rows
    border: "1px solid #ccc",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "transparent",
    margin: "10px 0",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "30% 1fr", // Key column adjusts automatically, Value column takes remaining space
    backgroundColor: "transparent",
    borderBottom: "1px solid #ccc",
    textTransform: "uppercase",
  },
  cellKey: {
    padding: "8px 12px",
    textAlign: "left",
    borderRight: "1px solid #ccc",
    textTransform: "uppercase",
    fontFamily: "DM Mono",
    color:"rgba(0, 0, 0, 0.7)",
    wordWrap: "break-word",
  },
  cellValue: {
    padding: "8px 12px",
    textAlign: "left",
    fontFamily: "DM Mono",
    wordWrap: "break-word",
  },
};

export default LocationDataDisplay;
