import React from 'react'
import { useLocation } from 'react-router-dom'
import services from '../../API_SERVICES/services';
import CustomUploadData from '../../Components/ProjectDBSEComponents/CustomUploadData';
import toast from "react-hot-toast";
const SharedUploadData = () => {
  const location = useLocation();
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [isMobile, setIsMobile] = React.useState(false);
  const [showCards,setShowCards] = React.useState(false);
  const [isUploadDataLoading, setIsUploadDataLoading] = React.useState(false);
  const [parentStates, setParentStates] = React.useState({
    pId: '',
    projName: '',
    DataCallTitle: '',
    title: '',
    message: '',
    dataTypes: '',
    call_for_data_fields: '',
    consent_and_permissions: '',
    core_question_author_name: '',
    core_question_how_this_sourced: '',
    core_question_where_did_this_come: '',
    optional_question_data: '',
    consent_optional_email: '',
    consent_donation_paid: '',
    privacy:'',
    contributionTypes:[]
  })
  const pId = location?.state;

  React.useEffect(() => {
    fetchPerticularProj();
    if (screenWidth <= 768) {
      setIsMobile(true);
    }
  }, [pId,screenWidth]);

  const fetchPerticularProj = () => {
    const apiname = "fetch-project-by-id";
    services.getProjectData(apiname, pId)
      .then((response) => {
        setParentStates({
          ...parentStates,
          pId: response?.data?.pId,
          projName: response?.data?.project_title,
          DataCallTitle: response?.data?.call_for_data_call_title,
          title: response?.data?.callfordata_title,
          message: response?.data?.callfordata_message,
          dataTypes: response?.data?.callfordata_type,
          call_for_data_fields: JSON.parse(response?.data?.call_for_data_fields),
          consent_and_permissions: response?.data?.consent_and_permissions,
          core_question_author_name: response?.data?.core_question_author_name,
          core_question_how_this_sourced: response?.data?.core_question_how_this_sourced,
          core_question_where_did_this_come: response?.data?.core_question_where_did_this_come,
          optional_question_data: JSON.parse(response?.data?.optional_question_data),
          callfordata_enabled: response?.data?.callfordata_enabled,
          privacy: response?.data?.project_privacy,
          contributionTypes:response?.data?.contribution_types ? JSON.parse(response?.data?.contribution_types) : []
        })
      }).catch((error) => {
        console.log(error);
      });
  }

  const dataUpload = (childStates) => {
    const apiname = "data_upload";
    var formData = new FormData();
    formData.append("dataUpload", childStates.image);
    formData.append("pId", pId);
    formData.append("user_id", "N/A");
    formData.append("contributor", "UNKNOWN");
    services
      .post(apiname, formData)
      .then((response) => {
        toast.success(response.data.message);
        sessionStorage.setItem(
          "data",
          JSON.stringify(response?.data?.data?.video)
        );
        sessionStorage.setItem(
          "id",
          JSON.stringify(response?.data?.data?.dcId)
        );
      })
      .catch((err) => {
        console.log("error",err)
        setIsUploadDataLoading(false);
        toast.error(err.response.data.error);
      });
  };
  
  const completeUpload = async (childStates) => {
    setIsUploadDataLoading(true);
    if (childStates.image) {
      dataUpload(childStates);
      const id = sessionStorage.getItem("id")
      childStates.ids = [...childStates.ids, { id: id }];
    }
    const apiname = "data-details";
    var per = "";
    if (childStates.permissionOne !== '') {
      per = per + childStates.permissionOne + ";";
    }
    if (childStates.permissionTwo !== '') {
      per = per + childStates.permissionTwo + ";";
    }
    if (childStates.permissionThree !== '') {
      per = per + childStates.permissionThree;
    }
    const data = {
      author: childStates.author ? childStates.author : "Anonymous",
      q1: childStates.whereDidThisComeFrom ? childStates.whereDidThisComeFrom : "N/A",
      q2: childStates.howWasThisSourced ? childStates.howWasThisSourced : "N/A",
      permissions: per,
      id: childStates.ids.length > 0 ? childStates.ids : [] ,
      call_for_data_fields: JSON.stringify(childStates.dataFields) ,
      optional_question_data: `[]`,
      consent_optional_email: childStates?.email,
      consent_donation_paid: childStates?.donationOrPaid,
      location:JSON.stringify(childStates?.locationData)
    };
    services
      .post(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
        setIsUploadDataLoading(false);
      })
      .catch((err) => {
        setIsUploadDataLoading(false);
        toast.error(err.response.data.error);
      });
  };
  const handleShowCards = () => {
    setShowCards(!showCards);
  }
  
  return (
    <div className='SUD-main-container' style={{height:screenHeight}}>
      <div className='SUD-Header'>
        <svg width="131" height="31" viewBox="0 0 131 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.084 31C36.6444 31 43.584 24.0604 43.584 15.5C43.584 6.93959 36.6444 0 28.084 0C19.5236 0 12.584 6.93959 12.584 15.5C12.584 24.0604 19.5236 31 28.084 31Z" fill="#0C090A"/>
<path d="M9.39882 2.00255C9.63282 1.34097 10.2529 0.899414 10.9481 0.899414C11.8567 0.899414 12.5933 1.64392 12.5933 2.56231V28.3316C12.5933 29.3091 11.8094 30.1015 10.8423 30.1015H1.94591C0.73477 30.1015 -0.110662 28.8886 0.297035 27.7359L9.39882 2.00255Z" fill="#0C090A"/>
<path d="M50.2285 9.92568H55.822C58.6511 9.92568 60.436 11.4264 60.436 14.0149C60.436 16.8867 58.2817 18.213 55.6043 18.213H52.1442V25.1521H50.2294V9.9248L50.2285 9.92568ZM55.7132 16.4297C57.3454 16.4297 58.4555 15.8639 58.4555 14.0368C58.4555 12.2097 57.3454 11.6659 55.7132 11.6659H52.1442V16.4297H55.7132Z" fill="#0C090A"/>
<path d="M63.9155 18.841V16.2306C63.9155 12.0755 66.0918 9.72656 69.9222 9.72656C73.7527 9.72656 75.929 12.0755 75.929 16.2306V18.841C75.929 22.996 73.7527 25.345 69.9222 25.345C66.0918 25.345 63.9155 22.996 63.9155 18.841ZM69.9222 23.496C72.8602 23.496 73.9703 21.6908 73.9703 18.841V16.2306C73.9703 13.3807 72.8602 11.5756 69.9222 11.5756C66.9842 11.5756 65.8741 13.3807 65.8741 16.2306V18.841C65.8741 21.6908 66.9842 23.496 69.9222 23.496Z" fill="#0C090A"/>
<path d="M79.6318 23.346H82.8094V11.7299H79.6318V9.9248H87.9456V11.7299H84.768V23.346H87.9456V25.1512H79.6318V23.346Z" fill="#0C090A"/>
<path d="M92.5122 9.9248H101.87V11.7089H94.4709V16.4078H101.195V18.1919H94.4709V23.3688H101.87V25.1529H92.5122V9.9248Z" fill="#0C090A"/>
<path d="M109.291 11.7299H104.807V9.9248H115.733V11.7299H111.249V25.1521H109.291V11.7299Z" fill="#0C090A"/>
<path d="M118.346 18.841V16.2306C118.346 12.0755 120.522 9.72656 124.353 9.72656C128.183 9.72656 130.36 12.0755 130.36 16.2306V18.841C130.36 22.996 128.183 25.345 124.353 25.345C120.522 25.345 118.346 22.996 118.346 18.841ZM124.353 23.496C127.291 23.496 128.401 21.6908 128.401 18.841V16.2306C128.401 13.3807 127.291 11.5756 124.353 11.5756C121.415 11.5756 120.305 13.3807 120.305 16.2306V18.841C120.305 21.6908 121.415 23.496 124.353 23.496Z" fill="#0C090A"/>
</svg>
{isMobile &&
  <i className="fa fa-bars fa-lg" onClick={handleShowCards}style = {{marginRight:"5%"}}aria-hidden="true"></i>}
      </div>
      <div className='SUD-content'>
        <CustomUploadData
          parentStates={parentStates}
          completeUploadData={(childStates) => completeUpload(childStates)}
          isLoading={isUploadDataLoading}
          pId={pId}
          showMobileUpload={showCards}
        />
      </div>
    </div>
  )
}

export default SharedUploadData