import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import services from "../../API_SERVICES/services";
import { isAuth, isId } from "../../Storage(Methods)/storage";
import { PopupModal } from "../DataPrivacy/PopupModal";
// import axios from "axios";
import path from "path";
import bulletpic from "../../Assets/Images/Ellipse.png";
import adminpic from '../../Assets/Images/admin.png'
import iconMp3 from '../../Assets/Images/mp3_icon.png'
import ellipse from '../../Assets/Images/Ellipse.png'
import './expandProject.css'
import Tree from 'react-d3-tree';


// const orgChart = {
//   "name": "Org-Name",
//   "children": [
//     {
//       "name": "Node-1",
//       "attributes": {
//         "department": "Version 1"
//       },
//       "children": [
//         {
//           "name": "Fork_Name",
//           "attributes": {
//             "department": "title 1"
//           }
//         }
//       ],
//       "children": [
//         {
//           "name": "Fork_Name",
//           "attributes": {
//             "department": "title 2"
//           }
//         }
//       ],
//     },
//     {
//       "name": "Node-2",
//       "attributes": {
//         "department": "Marketing"
//       },
//     }
//   ]
// }


const ProjectPageNew = () => {
  let location = useLocation();
  sessionStorage.setItem("projectName", location?.state?.data?.project_title);
  let navigate = useNavigate();
  const [datacontributions, setDatacontributions] = useState([])
  const [frames, setFrames] = useState([])
  const [models, setModels] = useState([])
  const [isTraceModalEnable, setIsTraceModalEnable] = useState(false);   // Control the modal state.
  const [traceModalState, setTraceModalState] = useState({
    data: [],
    isDataNotFound: false,
    isApiCall: false
  });

  let urlMap = new Map([
    ["chatgpt", "/chatgpt-demo"],
    ["mobilenet", "/Mobilenetmodel"]
  ]);


  const [contributorpopup, setContributorpopup] = useState(false);
  const [contributeVal, setContributeVal] = useState({
    screen1: false,
    profileAttatch: false,
    toggle: false,
    contributor: "",
    loading: false,
    screen2: false,
    invitee: "",
    toggleAdmin: false,
    isAdmin: false,
    screen3: false,
    fetched: "",
    contributorProfile: false,
    pending: false,
    nonAdmin: false,
    spin: false,
    screen4: false,
    original_proj: '',
    proj_privacy_forked: '',
    forkedproj: 'Copy Of ' + location?.state?.data?.project_title,
    expand: false,
    selectedframe: "",
  });

  const imageStyle = {
    width: "30px",
    height: "30px",
  };

  const { screen1, profileAttatch, toggle, contributor, loading, screen2, invitee, isAdmin, screen3, fetched, contributorProfile, spin, screen4, proj_privacy_forked, forkedproj, expand, selectedframe } = contributeVal;
  const spinCenter = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50% -50%)",
  };
  useEffect(() => {
    sessionStorage.setItem(
      "identifier",
      JSON.stringify(location?.state?.data?.pId)
    );
    loadContributor();
    loaddata();
    loadframes();
    loadModels();
    getTaceData();
  }, [location]);

  // Get the Trace data 
  const getTaceData = () => {
    setTraceModalState({
      ...traceModalState,
      isApiCall: true
    });
    const apiname = "fetch-project-collaborator";
    services
      .getbasic(apiname, location?.state?.data?.pId)
      .then((response) => {
        if (response?.data?.error === "No collaborators in this project") {
          // setTraceModalState({
          //   ...traceModalState,
          //   isDataNotFound: true,
          //   isApiCall: false
          // });
        } else {
          setTraceModalState({
            ...traceModalState,
            data: [response.data]
          })
          // let treeData = [];
          // let tempArray = [];
          // let projectName = response?.data?.project_name;
          // for (var i = 0; i <= 2; i++) {
          //   if (response.data.collaborators[i].name != null) {
          //     tempArray.push(response.data.collaborators[i]);
          //   }
          // }
          // treeData = {
          //   name: projectName,
          //   children: tempArray
          // }
          // setTraceModalState({
          //   ...traceModalState,
          //   data: treeData,
          //   isApiCall: false
          // });
        }
      })
      .catch((error) => {
        setTraceModalState({
          ...traceModalState,
          isApiCall: false
        });
      });
  }


  const loadframes = () => {
    const apiname = "fetch-frames";
    services
      .getbasic(apiname, location?.state?.data?.pId)
      .then((response) => {
        setFrames(response.data);
      })
      .catch((error) => {
      });
  };
  const loadModels = () => {
    const apiname1 = "fetch-model";
    services
      .getbasic(apiname1, location?.state?.data?.pId)
      .then((response) => {
        setModels(response.data);
      }).catch((error) => {
      });
  };
  const loaddata = () => {
    let apiname = "fetch-project-data";
    services
      .getbasic(apiname, location?.state?.data?.pId)
      .then((response) => {
        setDatacontributions(response);
      })
      .catch((error) => {
      });
  };
  const loadContributor = () => {
    setContributeVal({ ...contributeVal, spin: true });
    const apiname = "list-collabrator";
    services
      .getbasic(apiname, location?.state?.data?.pId)
      .then((response) => {
        var contprofile = false; var ispending = false; var isnonadmin = false;
        response?.data?.forEach((repo) => {
          if (repo.collaborator_mailid === isAuth().email) {
            contprofile = true;
            if (repo.is_admin === 'N') {
              isnonadmin = true;
            }
            if (repo.status === 'P') {
              ispending = true;
            }
          }
        });
        if (location?.state?.data?.forked_id == null) {
          setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, screen1: false, contributorProfile: contprofile, pending: ispending, profileAttatch: false, nonAdmin: isnonadmin, spin: false, original_proj: '' });
        }
        else {
          const apiname1 = "fetch-project-id";
          services
            .getbasic(apiname1, location?.state?.data?.forked_id)
            .then((responsefork) => {
              if (responsefork === null || responsefork.data == null) {
                setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, screen1: false, contributorProfile: contprofile, pending: ispending, profileAttatch: false, nonAdmin: isnonadmin, spin: false, original_proj: '' });
              }
              else {
                setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, screen1: false, contributorProfile: contprofile, pending: ispending, profileAttatch: false, nonAdmin: isnonadmin, spin: false, original_proj: responsefork.data });
              }

            }).catch((error) => {
              setContributeVal({ ...contributeVal, contributor: response, loading: true, screen3: false, screen1: false, contributorProfile: contprofile, pending: ispending, profileAttatch: false, nonAdmin: isnonadmin, spin: false });
            });
        }

      })
      .catch((error) => {
      });

  };
 
  const handleChange = (e) => {
    setContributeVal({ ...contributeVal, [e.target.name]: e.target.value });
  };
  const handleProjNameChange = (e) => {
    setContributeVal({ ...contributeVal, forkedproj: e.target.value });
  };

  const removeContributor = (contributorId) => {
    const apiname = "remove-collaborator";
    const data = { pId: location?.state?.data?.pId, mail_id: contributorId };
    services
      .delete(apiname, data)
      .then((response) => {
        toast.success(response.data.message);
        setContributeVal({ ...contributeVal, screen3: false });
        setTimeout(() => {
          loadContributor();
        }, 900);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  const forkproject = () => {
    const apiname = "fork-project";
    const data = {
      fork_id: location?.state?.data?.pId,
      user_id: isAuth().id,
      project_title: forkedproj,
      project_desc: location?.state?.data?.project_desc,
      project_privacy: proj_privacy_forked,
      imageurl: location?.state?.data?.projectimage,
      project_id: isId()
    };
    services.post(apiname, data)
      .then((response) => {
        toast.success("Project forked successfully");
        setContributeVal({ ...contributeVal, screen4: false });
        setTimeout(() => {
          navigate("/expand-fork-project", { state: { data: response.data.data, source: "add_Project" } }, { replace: true })
        }, 1800);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });

  };
  const handleFork = () => {
    setContributeVal({ ...contributeVal, screen4: true });
  };
  const handleJoin = () => {
    setContributeVal({ ...contributeVal, screen1: true });
    setContributorpopup(false);
  };
  const handleJoin2 = () => {
    if (isAuth().id !== location?.state?.data?.user_id) {
      setContributeVal({ ...contributeVal, spin: true });
      const apiname = "request-project";
      let eid = isAuth().email;
      const data = {
        pid: location?.state?.data?.pId,
        email_id: eid,
        owner_id: location?.state?.data?.user_id,
        user_id: isAuth().id,
        username: isAuth().username,
        project_name: location?.state?.data?.project_title,
      };
      services.post(apiname, data)
        .then((response) => {
          toast.success("Request sent successfully");
          setTimeout(() => {
            loadContributor();
          }, 900);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });

    }
    else {
      toast.success("You are owner of this project");
      setContributeVal({ ...contributeVal, screen1: false, spin: false });

    }
    setContributorpopup(false);

  };
  const handleJoinClick = () => {
    if (isAuth().id !== location?.state?.data?.user_id) {
      setContributeVal({ ...contributeVal, spin: true });

      const apiname = "join-project";
      let eid = isAuth().email;
      const data = {
        pid: location?.state?.data?.pId,
        email_id: eid,
        user_id: isAuth().id,
        username: isAuth().username,
      };
      services.post(apiname, data)
        .then((response) => {
          toast.success("Project joined successfully");
          setTimeout(() => {
            loadContributor();
          }, 900);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });

    }
    else {
      toast.success("You are owner of this project");
      setContributeVal({ ...contributeVal, screen1: false, spin: false });

    }

  }

  // Call the function when user clicks on the trace button.
  const handleTrace = () => {
    setIsTraceModalEnable(true);
  }

  const handleAdminChange = (e) => {
    setContributeVal({
      ...contributeVal,
      isAdmin: !isAdmin,
      toggleAdmin: e.target.checked,
    });

  };
  const handleprivacyChange = e => {
    const target = e.target;
    if (target.checked) {
      setContributeVal({ ...contributeVal, proj_privacy_forked: target.value });

    }
  };
  const inviteCollabrator = () => {

    if (isAuth().email.toLowerCase() !== invitee.toLowerCase()) {
      const apiname = "invite_collabrator";
      setContributeVal({ ...contributeVal, spin: true, screen2: false });

      const data = {
        pid: location?.state?.data?.pId,
        project_name: location?.state?.data?.project_title,
        email_id: invitee,
        admin: isAdmin ? 'Y' : 'N',
        user_name: isAuth().username,
      }
      services.post(apiname, data)
        .then((response) => {
          toast.success("invited successfully");
          setContributeVal({ ...contributeVal, screen2: false, isAdmin: false, invitee: "", spin: false })

        })
        .catch((error) => {
          toast.error(error.response.data.error);
          setContributeVal({ ...contributeVal, screen2: false, isAdmin: false, invitee: "", spin: false })

        });

    }
    else {
      toast.success("You are owner of this project");
      setContributeVal({ ...contributeVal, screen2: false, isAdmin: false, invitee: "", spin: false })

    }
  };
  let joinbutton = <label>  </label>;
  let traceButton = <label> <button type="button" className="btnjoin me-2" onClick={() => handleTrace()}>TRACE </button></label>;
  let forkbutton = <label></label>
  let joincntbutton = <label>  </label>;

  if (location?.state?.source !== "add_Project") {
    if (isAuth().id === location?.state?.data?.user_id) {
      joinbutton = <label>  </label>;
      if (location?.state?.data?.project_privacy === "PUBLIC AND OPEN") {
        forkbutton = <button type="button" className="btnjoin me-2" onClick={handleFork}>FORK </button>
        joincntbutton = <button type="button" className="invitebtndisabled" disabled> JOIN</button>

      }
      else if (location?.state?.data?.project_privacy === "PUBLIC AND CLOSED") {
        joincntbutton = <button type="button" className="invitebtndisabled" disabled>REQUEST TO JOIN</button>

      }
    }
    else if (location?.state?.data?.project_privacy === "PUBLIC AND OPEN") {
      forkbutton = <button type="button" className="btnjoin me-2" onClick={handleFork}>FORK </button>

      if (contributorProfile) {
        joincntbutton = <button type="button" className="invitebtndisabled" onClick={handleJoin} disabled> JOIN</button>
      }
      else {
        joinbutton = <button onClick={handleJoin} type="button" className="btnjoin me-2"> JOIN </button>
        joincntbutton = <button type="button" className="invitebtn" onClick={handleJoin} > JOIN</button>

      }
    }
    else if (location?.state?.data?.project_privacy === "PUBLIC AND CLOSED") {
      forkbutton = <button type="button" className="btnjoin me-2" onClick={handleFork}>FORK </button>

      if (contributorProfile) {
        //joinbutton = <label> JOINED</label>;
        joincntbutton = <button type="button" className="invitebtndisabled" onClick={handleJoin2} disabled> REQUEST TO JOIN</button>

      }
      else {
        joinbutton = <button onClick={handleJoin2} type="button" className="btnjoinlg me-2"> REQUEST TO JOIN </button>
        joincntbutton = <button type="button" className="invitebtn" onClick={handleJoin2}> REQUEST TO JOIN</button>

      }
    }
  }
  const closeContributorPanel = () => {
    setContributorpopup(false);
  }
  const handleEnterClick = () => {
    setTimeout(() => {
      navigate("/decode", { state: { data: location?.state?.data, source: "expanddt" } }, { replace: true });
      sessionStorage.setItem(
        "identifier",
        JSON.stringify(location?.state?.data?.pId)
      );
    }, 1800);
  };

  const getFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const filename = urlObject.pathname.split('/').pop();
    return filename;
};

  // Create the Dynamic Tree Nodes
  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
    const text = nodeDatum?.originalProject.length > 12 ? nodeDatum?.originalProject.substring(0, 15) + "..." : nodeDatum?.originalProject;
    const textWidth = text.length * 8;
    const padding = 5;
    const fixedX = -((textWidth + 3 * padding + 30) / 2);
    const yOffset = -20;
    const rectWidth = textWidth + padding + 40;
    return (
      <g>
        <rect
          width={rectWidth}
          height="30"
          x={fixedX}
          y={yOffset}
          rx="15"
          ry="15"
          fill="rgb(229,255,128)"
          stroke='none'
        />
        <image
          href={ellipse}
          x={fixedX + 5}
          y={yOffset + 5}
          width={padding + 20}
          height="20"
        />
        <text fill="black" x={fixedX + 32} y={yOffset + 14} dy=".35em" stroke="none" fontWeight="normal">
          {text}
        </text>
      </g>
    );
  };

  // Trace Modal Tree UI
  const TraceModal = () => {
    return (
      <div className="modal-outer-container">
        <div className="center-div">
          <div className="traceModal-grid-joinContributor">
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexGrow: 1, justifyContent: "end" }}>
                <span className="header-text"> Trace </span>
              </div>
              <div style={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
                <button type="button" className="close-button mr-2" onClick={() => setIsTraceModalEnable(false)}>
                  x
                </button>
              </div>
            </div>
            <br></br>
            <div className="center-container-box">
              {traceModalState.isDataNotFound ?
                <p className="m-4"> No collaborators found in this project. </p>
                :
                <div id="treeWrapper" className="treeWrapperClass" style={{ width: '100%', height: '100%' }}>
                  <Tree
                    data={traceModalState.data}
                    renderCustomNodeElement={renderRectSvgNode}
                    orientation="vertical"
                    translate={{ x: 400, y: 40 }}  // values for centering the tree
                    scaleExtent={{ min: 0.8, max: 1.5 }}   // values for zooming
                    zoomable={false}
                    draggable={false}
                    separation={{ siblings: 2, nonSiblings: 2 }}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <>
      {location.state === null ? (
        <h4
          style={{
            fontSize: "35px",
            fontWeight: "lighter",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50% , -50%)",
          }}
        >
          404 PAGE NOT FOUND
        </h4>
      ) : (
        <>
          {!expand ? null : (
            <div className="outercontainer">
              <div className="gridframeContributor">
                <div className="framegridContainer">
                  <div className="ExpandGridFrame">
                    <p style={{ fontSize: "42px", color: "#4E4E4E", textTransform: "uppercase" }}>{selectedframe?.frame_name}</p>
                    <i
                      className="far fa-times-circle"
                      onClick={() => setContributeVal({ ...contributeVal, expand: !expand })}
                    ></i>
                  </div>
                  <div className="ExpandGrid">
                    <div style={{ height: "220px"}}>
                      <h5>Description</h5>
                      <p style={{ paddingLeft: "10px", width: "80%" }}>{selectedframe?.frame_desc}</p>
                    </div>
                    <div style={{ height: "220px" }}>
                      <h5>Inspiration And Attributes</h5>
                      <p style={{ paddingLeft: "10px", width: "80%" }}>{selectedframe?.frame_attr}</p>
                    </div>
                    <div style={{ height: "220px" }}>
                      <h5>Contributions</h5>
                    </div>
                    <div style={{ height: "220px" }}>
                      <img
                        src={selectedframe?.frame_image}
                        alt="frameimage"
                        width="100%"
                        height="210x"
                        style={{ borderRadius: "10px" }}
                      />
                    </div>

                  </div>

                </div>
              </div>
            </div>
          )}
          {spin === true && (
            <div style={spinCenter}>
              <div
                className="spinner-border"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              ></div>
            </div>
          )}
          {(location?.state?.source !== "add_Project") ?
            <p className="ms-3" onClick={() => navigate('/data-contribute', { replace: true })}>
              EXPLORER /{location?.state?.data?.project_title}
            </p>
            :
            <p className="ms-3" >
              /{location?.state?.data?.project_title}
            </p>
          }
          <div className="d-flex mt-5 justify-content-between maindiv">
            <h1 className="text-uppercase mt-2 ms-3 plachetitle">
              {location?.state?.data?.project_title}
            </h1>
            <div style={{ display: "flex" }}>
              {traceButton}
              {joinbutton}
              {forkbutton}
              <div className="contributorScrolldiv" onClick={() => setContributorpopup(true)}>
                <ul className="contributorScrolln">
                  {loading && contributor?.data?.map((d) => (
                    d.status === 'A' ?
                      <li key={d.username}>
                        <span style={{ display: "none" }}>
                          {d.collaborator_mailid === isAuth().email ? 'You' : d.username}
                        </span>
                        <img alt="download"
                          src={bulletpic} height="20px" width="20px"
                        />
                      </li> : ''
                  )
                  )}
                </ul>
                {contributor?.data?.length <= 0 &&
                <label style={{display:"flex",alignItems:"center",marginRight:"20px"}}>INVITE</label>
                }
                <i className="fas fa-plus" style={{ alignSelf: "end", marginBottom: "15px" }} ></i>
              </div>
              <button type="button" className="publicbtn me-2">
                {location?.state?.data?.project_privacy}
              </button>
            </div>
          </div>
          {!screen2 ? null : (
            <div className="outercontainer">
              <div className="gridJoin">
                <div className="grid-joinContributor">
                  <span className="inviteText mt-4 mb-4"> Invite Collaborators? </span>
                  <p>Send an invite link to someone you want to contribute to the project.</p>
                  <input
                    type="text"
                    className="inviteBoxView mt-2 mb-2"
                    placeholder="Enter an Email"
                    autoComplete="off"
                    value={invitee}
                    name="invitee"
                    onChange={handleChange}
                  />
                  <div className="uploadinput " style={{ textAlign: 'left' }}>
                    <div className="d-flex flex-column">
                      <div className="mt-3">
                        <input
                          type="checkbox"
                          checked={isAdmin}
                          onChange={handleAdminChange}
                        />    Make admin?
                      </div>
                      <span className="mt-4">Admin users are granted the same permissions you have. (including deleting the project or inviting others) Admin access can be granted later but not taken away.</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around mt-4">
                    <button
                      type="button" className="joinbtn mr-2"
                      onClick={() => setContributeVal({ ...contributeVal, screen2: false })}
                    >SKIP</button>
                    <button
                      type="button"
                      className={invitee === '' ? "invitebtndisabled" : "invitebtn"}
                      disabled={invitee === ''}
                      onClick={() => inviteCollabrator()}
                    >
                      INVITE
                    </button></div>
                </div></div></div>
          )}
          {!screen3 ? null : (
            <PopupModal>
              <h4>ARE YOU SURE YOU WANT TO REMOVE THIS CONTRIBUTOR?</h4>
              <div className='d-flex justify-content-between'>
                <button
                  type='button'
                  className='backbtndelete'
                  onClick={() =>
                    setContributeVal({ ...contributeVal, screen3: false })
                  }>
                  BACK
                </button>
                <button
                  type='button'
                  className='deletebtn'
                  onClick={() => { removeContributor(fetched.collaborator_mailid) }}>
                  EXIT
                </button>
              </div>
            </PopupModal>
          )}
          {isTraceModalEnable ? TraceModal() : null}
          {!screen1 ? null : (
            <div className="outercontainer">
              <div className="gridJoin">
                <div className="grid-joinContributor">
                  <span className="headerText"> JOIN </span>
                  <br></br>
                  <p className="descText">
                    To join this project and become a contributor, you must
                    attach your profile information. This will make your profile
                    page visible to anyone with access to see this project’s
                    contributors.{" "}
                  </p>
                  {toggle}
                  {profileAttatch}
                  <div className="uploadinput text-center">
                    <div className="d-flex flex-column">
                      <div className="mt-3">
                        <p className="row justify-content-md-center"></p>
                        <label className="check_switch">
                          <input
                            type="checkbox"
                            className="checkinput"
                            onChange={(e) => {
                              setContributeVal({
                                ...contributeVal,
                                profileAttatch: !profileAttatch,
                                toggle: e.target.checked,
                              });
                            }}
                          />
                          <span className="slider-join"></span>
                        </label>
                      </div>
                      <span className="mt-4">Attach my profile</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around mt-4">
                    <button
                      type="button" className="joinbtn mr-2"
                      onClick={() => setContributeVal({ ...contributeVal, screen1: false })}
                    >BACK</button>
                    <button
                      type="button"
                      className={profileAttatch ? "joinbtn mr-2" : "join-inactive mr-2"}
                      onClick={handleJoinClick}
                      disabled={!profileAttatch}
                    >
                      JOIN PROJECT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!screen4 ? null : (
            <div className="outercontainer">
              <div className="gridFork">
                <div className="gridcontent">
                  <h4 className="mt-4 mb-4 text-center">
                    Fork project?
                  </h4>
                  <p>Forking this project will duplicate it to your profile, carrying over all modules and data, but not current contributors. You will be the admin of the project, and that project will be traceable to this project.</p>
                  <input
                    type="text"
                    className="  ms-2 my-3 plachetitle" style={{ width: '100%', color: '#4e4e4e' }}
                    placeholder={location?.state?.data?.project_title}
                    autoComplete="off"
                    value={forkedproj}
                    name="forkedproj"
                    onChange={handleProjNameChange}
                  />
                  <br></br>
                  <p>Select Project Privacy Setting:</p>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="privacySetting" id="privacySetting1" value="PRIVATE" onChange={handleprivacyChange} />
                    <label className="form-check-label" for="privacySetting1">
                      PRIVATE<br></br>
                      Project is not listed and collaborators must be invited directly
                    </label>
                  </div>
                  <br></br>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="privacySetting" id="privacySetting1" value="PUBLIC AND CLOSED" onChange={handleprivacyChange} />
                    <label className="form-check-label" for="privacySetting1">
                      PUBLIC AND CLOSED<br></br>
                      Project is listed publicly in our project database and others can request to join
                    </label>
                  </div>
                  <br></br>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="privacySetting" id="privacySetting1" value="PUBLIC AND OPEN" onChange={handleprivacyChange} />
                    <label className="form-check-label" for="privacySetting1">
                      PUBLIC AND OPEN<br></br>
                      Project is listed publicly in our project database and anyone can join and start editing.
                    </label>
                  </div>
                  <br></br>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <button className='borderbtn' onClick={() => setContributeVal({ ...contributeVal, screen4: false })} style={{ width: '100%', borderRadius: '50px', padding: '15px', border: '1px solid #474843', backgroundColor: '#eaedde' }}>Cancel</button>
                    </div>
                    <div className='col-lg-6'>
                      <button className='solidbtn' onClick={forkproject} style={{ width: '100%', borderRadius: '50px', backgroundColor: '#474843', color: '#fff', padding: '15px', border: '0px' }}>Confirm</button>
                    </div>
                  </div>
                </div>
              </div>   </div>
          )}
          {!contributorpopup ? null : (<>
            <div className="backdrop" onClick={closeContributorPanel}></div>
            <div className="backdropinner" style={{ height: "442px", width: "680px", borderRadius: "40px", backgroundColor: "#EAEDDE", marginLeft: "20%" }} >
              <div className="cntributor" >
                <div style={{ justifySelf: "center", textAlign: "center" }}>
                  <p
                    style={{
                      fontSize: "24px",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    Project Contributors
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    From project contributors:
                  </p>
                </div>
                <div className="cntributorinner">
                  {loading && contributor?.data?.map((d) => (
                    d.status === 'A' ?
                      <div key={d.username} className="cntributorlist" onClick={() => navigate('/Userprofile', { state: { profileid: d.user_id, profileName: d.username, source: "project" } }, { replace: true })}>
                        <span>  <img alt="download" src={bulletpic} height="24px" width="24px" />    {d.collaborator_mailid === isAuth().email ? d.username : d.username}</span>
                        {d.is_admin === 'Y' ? <span><img alt="download" src={adminpic} height="24px" width="24px" /> Admin  </span> : <span>Member </span>}
                      </div> : ''
                  )
                  )}
                </div>
                {joincntbutton}
              </div></div>
          </>)}
          <div className="projectpageGrid">
            <div className="projectpage-item1">
              <textarea
                className="projpagedesc"
                value={"PROJECT DESCRIPTION \n\n" + location?.state?.data?.project_desc}
                rows="6" readOnly={true}
              ></textarea></div>
              <Link  to={"/project-dbse"}
                state={{ data: location?.state?.data, source: "expand" }}
                style={{textDecoration:"none"}}
              >
            <div className="projectpage-item2">
              <div className="calldataspan">
                <span style={{ fontSize: "14px", color: " rgba(78, 78, 78, 0.7)" }}>DATASET</span>
                {location?.state?.data?.callfordata_enabled === 'Y' ? <button className="calldatawidget">CALL FOR DATA</button> : null}
              </div>
              <table className="table" width={"90%"}>
                <thead>
                  <tr style={{ fontSize: "13px", color: " rgba(78, 78, 78, 0.7)", textTransform: "lowercase" }}>
                    <th>#</th>
                    <th >icon</th>
                    <th>file name</th>
                    <th>author(s)</th>
                    <th>contributor</th>
                  </tr>
                </thead>
                {datacontributions?.data?.length > 0 && (
                  <tbody>
                    {datacontributions?.data.slice(0, 2)?.map((info, key = 0) => (
                      <tr style={{ color: " rgba(78, 78, 78, 0.7)", borderColor: "#4E4E4E" }}
                        key={key + 1}
                      >
                        <td style={{ fontSize: "13px" }}>{key + 1}</td>
                        <td>
                          {" "}
                          <img
                            src={info?.video.includes(".mp3") ? iconMp3 : info?.video}
                            style={imageStyle}
                            alt="projimg"
                          />
                        </td>
                        <td style={{ fontSize: "13px" }}>
                          {getFileNameFromUrl(info?.video)}
                        </td>
                        <td style={{ fontSize: "13px" }}>{info.author}</td>
                        <td style={{ fontSize: "13px" }}>{info.contributor}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              <Link style={{ alignSelf: "end" }} className="projectLink"
                to={"/project-dbse"}
                state={{ data: location?.state?.data, source: "expand" }}
              >
                <i className="fas fa-plus" ></i>
              </Link>
            </div>
            </Link>
          </div>
          <div className="projectpageGrid2">
            {location?.state?.data?.projectimage === null ? (
              <div
                className="grid-item-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid black",
                  borderRadius: "30px",
                }}
              >
                <h5>No Project Image</h5>
              </div>
            ) : (
              <div className="projectpage-item3">
                <img
                  src={location?.state?.data?.projectimage}
                  alt="projectimage"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "28px",
                  }}
                />
              </div>
            )}
            <div className="projectpage-item4">
              <p style={{ fontSize: "14px", color: "#4E4E4EB2" }}>FRAMES</p>
              <br></br>
              {frames?.data?.length > 0 ? (
                <div style={{ width: "80%", marginLeft: "10%" }}>
                  {frames?.data?.map((frame, index) => (
                    <div key={index} onClick={() => setContributeVal({ ...contributeVal, expand: true, selectedframe: frame })}
                    >
                      <h4 style={{
                        fontSize: "38px", color: "rgba(0, 0, 0, 0.54)", whiteSpace: "nowrap",
                        overflow: "hidden", textOverflow: "ellipsis"
                      }} className="text-uppercase">{frame.frame_name}</h4>
                      <p className="text-uppercase max-lines3">
                        {frame.frame_desc}
                      </p>
                    </div>
                  ))}
                </div>
              ) : frames?.data?.length === 0 ? (
                <p className="text-center" style={{ textAlign: "center", width: "100%" }}  >
                  NOT FOUND          </p>
              ) : null}
              <br></br>
              <i className="fas fa-plus" style={{ alignSelf: "start" }} onClick={() => navigate('/CreateFrames', { replace: true })}></i>
            </div>
            <div className="projectpage-item4">
              <p style={{ fontSize: "14px", color: "#4E4E4EB2" }}>PROTOTYPE</p>
              <br></br>
              <br></br>
              {models?.data?.length > 0 ? (
                <div style={{ width: "80%", marginLeft: "10%" }}>
                  {models?.data?.map((d, index) => (
                    index === 0 ?
                      <div key={d.pId + 1}>
                        <h4 style={{ fontSize: "25px", textTransform: "uppercase" }}>{d.name}</h4>
                        <h4
                          style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontSize: "25px",
                            textOverflow: "ellipsis",
                            textTransform: "uppercase"
                          }}
                        >
                          {d.model_type}
                        </h4>
                        <br></br>
                        <br></br>
                        <a
                          style={{  textDecoration: "none", color: "rgba(78, 78, 78, 0.7) " }}
                          onClick={() => navigate(urlMap?.get(d.model_type), { replace: true, state: { source: "prototype" } })}
                        >DEMO
                        </a>
                      </div>
                      : null
                  ))}
                </div>
              ) : frames?.data?.length === 0 ? (
                <p className="text-center" style={{ textAlign: "center", width: "100%" }}  >
                  NOT FOUND          </p>
              ) : null}
            </div>
          </div>
          {/* {isAuth().id === location?.state?.data?.user_id ? */}
          <button className="enterproject" onClick={handleEnterClick}>Enter</button>
          {/* : null} */}
        </>
      )}
    </>
  );
};
export default ProjectPageNew;