import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EmbedLocationComponent.css";

const EmbedLocationComponent = ({ onLocationDataChange,top,left}) => {
  const [isLocationAllowed, setIsLocationAllowed] = useState(false);
  const [precision, setPrecision] = useState(0);
  const [locationData, setLocationData] = useState({
    NA: "N/A",
    hemisphere: "",
    country: "",
    city: "",
    exact: "",
  });
 
  const [displayValue, setDisplayValue] = useState("N/A");
  const OPEN_CAGE_API_KEY = "2a6ee4266210472fb419779524253234";
  const [showTooltip, setShowTooltip] = useState(false);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          if (precision > 0) await getLocationDetails(lat, lon);
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getLocationDetails = async (lat, lon) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lon}&key=${OPEN_CAGE_API_KEY}`
      );
      const location = response.data.results[0].components;
      const locationInfo = {
        hemisphere: lat > 0 ? "Northern Hemisphere" : "Southern Hemisphere",
        country: location.country || "",
        city: location.city || location.town || location.village || "",
        exact: `${lat.toFixed(6)}, ${lon.toFixed(6)}`,
      };

      const updatedLocationData = {
        ...locationData,
        hemisphere: precision >= 1 ? locationInfo.hemisphere : "",
        country: precision >= 2 ? locationInfo.country : "",
        city: precision >= 3 ? locationInfo.city : "",
        exact: precision >= 4 ? locationInfo.exact : "",
      };

      setLocationData(updatedLocationData);
      onLocationDataChange(updatedLocationData); // Send updated data to parent
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  const handleSliderChange = (value) => {
    if (isLocationAllowed) {
      setPrecision(value);
      let newValue = "N/A";

      switch (value) {
        case 1:
          newValue = locationData.hemisphere;
          break;
        case 2:
          newValue = locationData.country;
          break;
        case 3:
          newValue = locationData.city;
          break;
        case 4:
          newValue = locationData.exact;
          break;
        default:
          newValue = "N/A";
      }

      setDisplayValue(newValue);
    }
  };

  const handleAllowLocationChange = (event) => {
    const isChecked = event.target.checked;
    setIsLocationAllowed(isChecked);
    setPrecision(0); // Reset precision when disabling location
    if (!isChecked) {
      const clearedData = {
        NA: "N/A",
        hemisphere: "",
        country: "",
        city: "",
        exact: "",
      };
      setLocationData(clearedData);
      onLocationDataChange(clearedData); // Send cleared data to parent
    } else {
      getLocation(); // Get location if location is allowed
    }
  };

  useEffect(() => {
    if (precision > 0 && isLocationAllowed) {
      getLocation();
    }
  }, [precision, isLocationAllowed]);

  return (
    <div className="embed-location-container">
      <div className="embed-allow-location">
        <input
          type="checkbox"
          id="embed-allow-location"
          onChange={handleAllowLocationChange}
        />
        <label className="embed-allow-label" htmlFor="embed-allow-location">
          Allow Location?*
        </label>
        <span className="embed-info-icon"  onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>i</span>
        {showTooltip && (
      <div className="embed-locationtooltip" style={{top:top,left:left}}>
        <strong>Share what you want</strong>
        <p>Drag the circle to your preferred location accuracy.</p>
      </div>
    )}
      </div>

      <div className="embed-slider-input-container">
        <div className="embed-slider-labels">
          <span>N/A</span>
          <span>HEMISPHERE</span>
          <span>COUNTRY</span>
          <span>CITY</span>
          <span>EXACT</span>
        </div>
        <div className="embed-slider-track">
          <div
            className="embed-slider-fill"
            style={{ width: `${(precision / 4) * 100}%` }}
          ></div>
          {[0, 1, 2, 3, 4].map((step) => (
            <div
              key={step}
              className={`embed-slider-thumb ${
                precision === step ? "embed-thumbactive" : "embed-thumbinactive"
              }`}
              style={{
                left: `${(step / 4) * 100}%`,
                backgroundColor:
                  isLocationAllowed && precision >= step ? "rgba(0, 0, 0, 1)" : "#EBF0DF",
                cursor: isLocationAllowed ? "pointer" : "not-allowed",
              }}
              onClick={() => handleSliderChange(step)}
            ></div>
          ))}
        </div>

        <input
          type="range"
          min="0"
          max="4"
          value={precision}
          className="embed-hidden-slider"
          onChange={(e) => handleSliderChange(parseInt(e.target.value))}
          disabled={!isLocationAllowed}
        />
      </div>
      <div className="embed-location-description">
      <p className="embed-description-text">
        Location data adds dimensionality to the data you have uploaded.
      </p>
      </div>
    </div>
  );
};

export default EmbedLocationComponent;
