import React from "react";
import "./UploadData.css";
import { CustomInputField } from "./CustomInputField";
import DrawingArea from "./Canvas";
import Pencil from "../../Assets/Images/drawicon.png";
import Ellipse from "../../Assets/Images/Ellipse.png";
import services from "../../API_SERVICES/services";
import { isAuth } from "../../Storage(Methods)/storage";
import { validateInput,getMediaImage,getFileUploadLabel,getAcceptedFiles } from "../../utils/general";
import AudioRecorder from "./AudioRecorder";
import heic2any from "heic2any";
import toast from "react-hot-toast";

const UploadData = ({
  completeUploadData,
  parentStates,
  closeModal,
  pId,
  isLoading,
  projName,
  marginValue,
  justifyContent
}) => {
  const [state, setState] = React.useState({
    image: "",
    renderImage: "",
    author: "",
    ids:[],
    whereDidThisComeFrom: "",
    howWasThisSourced: "",
    email: "",
    donationOrPaid: "",
    permissionOne: "",
    permissionTwo: "",
    permissionThree: "",
    dataFields: [],
    optionalFields: [],
    isSubmitting: false,
  });
  const [isDataFieldsUpdated, setIsDataFieldsUpdated] = React.useState(false);
  const projectName = sessionStorage.getItem("projectName");
  const [svgFile, setSvgFile] = React.useState(null);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [currentCard, setCurrentCard] = React.useState(1);
  const [showCanva, setShowCanva] = React.useState(false);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };
  const handleCanva = (index) => {
    setShowCanva(!showCanva);
    if (!showCanva) {
      updateSvgDataFields(index, null); // Update the answer property to null
    }
    setSvgFile(null);
    setState((prevState) => ({ ...prevState, image: "" }));
  };
 
  const handleSaveAudio = (fileData, index) => {
    const { file } = fileData;
    updateAudioDataFields(index, file); // Store the audio file in the state
};

const updateAudioDataFields = (index, audioFile) => {
  const tempArray = [...state.dataFields];

  if (tempArray[index].answer instanceof File) {
    // If the audio file is already stored in the state, update it
    tempArray[index] = {
      ...tempArray[index],
      answer: audioFile,
    };
  } else {
    // If the audio file is not stored in the state, add it
    tempArray[index] = {
      ...tempArray[index],
      answer: audioFile,
    };
  }
  setState({ ...state, dataFields: tempArray });
};

  const handleDeleteAudio = (index) => {
    setState((prevState) => {
      const updatedAudioFiles = prevState?.audioFiles?.filter((_, i) => i !== index);
      return { ...prevState, audioFiles: updatedAudioFiles };
    });
  };

  const hasAnswerWithExtension = state?.dataFields?.some((field) => {
    return field.answer && (
      field.answer.type === 'image/png' || 
      field.answer.type === 'image/jpg' || 
      field.answer.type === "audio/mpeg" || 
      field.answer.type === 'audio/aiff' || 
      field.answer.type === 'audio/wav' || 
      field.answer.type === 'image/svg+xml' ||
      field.answer.type === "video/mp4" ||
      field.answer.type === "video/quicktime" ||
      field.answer.type === "application/pdf" ||
      field.answer.type === "text/plain" ||
      (field.answer.name && (
          field.answer.name.endsWith('.obj') || 
          field.answer.name.endsWith('.stl') || 
          field.answer.name.endsWith('.usdz') || 
          field.answer.name.endsWith('.glTF')
      ))
    );
});
  
  const hasAudioOrImage = state?.dataFields?.some((field) => {
    return [
      'AUDIO (.MP3)',
      'IMAGE (.PNG)',
      'IMAGE (.JPG)',
      "Image - Upload",
      "Audio - Upload",
      "Audio - Record",
      "Video - Upload",
      "3D - Upload",
      "TEXT - Upload"
    ].includes(field.selectedValue);
  });

  const getFirstMatchingMediaURL = (dataFields) => {
    for (const field of dataFields) {
        // Check if the item name ends with specific 3D model file extensions
        if (field?.answer?.name?.endsWith('.obj') || 
            field?.answer?.name?.endsWith('.stl') || 
            field?.answer?.name?.endsWith('.usdz') || 
            field?.answer?.name?.endsWith('.glTF')) {
            return field.answer; // Return the answer if it matches the 3D file types
        }

        // Check for audio file types
        if (field?.answer?.type === "audio/mpeg" || 
            field?.answer?.type === 'audio/aiff' ||  
            field?.answer?.type === 'audio/wav') {
            return field.answer;
        } 
        
        // Check for image, video, PDF, and plain text file types
        else if (
            field?.answer?.type === "image/png" ||
            field?.answer?.type === "image/jpg" ||
            field?.answer?.type === "image/svg+xml" ||
            field?.answer?.type === "image/jpeg" ||
            field?.answer?.type === "video/mp4" ||
            field?.answer?.type === "video/quicktime" ||
            field?.answer?.type === "application/pdf" ||
            field?.answer?.type === "text/plain"
        ) {
            return field?.answer;
        }
    }
    return state?.renderImage;
};

  // Get the first matching media URL from the dataFields array
  const mediaURL = getFirstMatchingMediaURL(state?.dataFields);
  
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    setState({
      ...state,
      optionalFields: parentStates?.optional_question_data
        ? parentStates?.optional_question_data
        : [],
      dataFields: parentStates?.call_for_data_fields
        ? parentStates?.call_for_data_fields
        : [],
      dataTypes: parentStates?.dataTypes ? parentStates?.dataTypes : [],
      author: parentStates?.core_question_author_name,
      howWasThisSourced: parentStates?.core_question_how_this_sourced,
      whereDidThisComeFrom: parentStates?.core_question_where_did_this_come,
    });
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const labelStyles = {
    margin: "15px 0 15px 0",
    fontSize: "16px",
  };

  const labelConfirm = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "14px",
    marginBottom: "10px",
  };
  const labelConfirm1 = {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "14px",
    marginBottom: "10px",
    fontWeight: "bold",
  };
  const imageStyle = {
    width: "40px",
    height: "40px",
    cursor: "pointer",
  };

  const UploadedimageStyle = {
    width: "100px",
    height: "100px",
  };
 

  const handleNext = () => {
    if (currentCard === 2) {
      if (
        !validateInput(state.author, "author") ||
        !validateInput(state.howWasThisSourced, "howWasThisSourced")
      ) {
        return;
      }
    }
    setCurrentCard(currentCard + 1);
  };

  const handleBack = () => {
    if (currentCard === 1) {
      closeModal(false);
    }
    setCurrentCard(currentCard - 1);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setState((prevState) => ({
      ...prevState,
      image: file,
    }));
    const reader = new FileReader();
    reader.onload = () => {
      setState((prevState) => ({
        ...prevState,
        renderImage: reader.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  React.useEffect(() => {
    if (svgFile) {
      setState((prevState) => ({
        ...prevState,
        image: svgFile,
      }));
    }
  }, [svgFile]);

  const setStateData = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const updateDataFields = async (event, index, inputName) => {
    const isFileType = event.target.type === "file";
    const tempArray = [...state.dataFields];
  
    if (isFileType) {
      const file = event.target.files[0];
      if (file) {
        const fileName = file.name.toLowerCase();
        const fileType = file.type || (fileName.endsWith(".heic") ? "image/heic" : ""); // Fallback for empty type
        const acceptedFileTypes = getAcceptedFiles(state.dataFields[index].selectedValue).split(",");
      
        // Check if the uploaded file type is accepted
        if (!acceptedFileTypes.some(type => fileType.includes(type.trim()) || fileName.endsWith(type.trim()))) {
          // Show error toast for invalid file type
          toast.error(`Invalid file type. Please upload files of type: ${acceptedFileTypes.join(", ")}`);
          return;
        }
        if (fileType === "image/heic") {
          try {
            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/png",
            });
  
            const convertedFile = new File([convertedBlob], `${file.name}.png`, {
              type: "image/png",
            });
  
            tempArray[index] = {
              ...tempArray[index],
              answer: convertedFile,
            };
          } catch (error) {
            console.error("Error converting HEIC file:", error);
            return;
          }
        } else {
          // For other file types, directly update the state
          tempArray[index] = {
            ...tempArray[index],
            answer: file,
          };
        }
        setState({ ...state, dataFields: tempArray });
      }
    } else {
      // Handle non-file input
      const inputValue = event.target.value;
      const tempObj = {
        question: state.dataFields[index].question,
        answer: inputValue,
        selectedValue: state.dataFields[index].selectedValue,
      };
      tempArray[index] = tempObj;
      setState({ ...state, dataFields: tempArray });
    }
  };


  const updateSvgDataFields = (index, svgFile) => {
    const tempArray = [...state.dataFields];
  
    tempArray[index] = {
      ...tempArray[index],
      answer: svgFile, // Update the answer property with the svgFile
    };
  
    setState({ ...state, dataFields: tempArray });
  };
  const updateOptionalfields = (event, index) => {
    var optArray = state.optionalFields;
    var optObj = {
      question: state.optionalFields[index].question,
      value: state.optionalFields[index].question,
    };
    optArray[index] = optObj;
    setState({ ...state, optionalFields: optArray });
  };

  const setPermissions = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const submitForm = () => {
    setState({ ...state, isSubmitting: true });
    const filesToUpload = state.dataFields.filter((field) => field.answer instanceof File);
    if (filesToUpload?.length === 0) {
      // If no files to upload, directly call completeUploadData with the current state
      completeUploadData(state);
      return;
    }
    const promises = filesToUpload.map((field, index) => {
      return imageUploadS3(field.answer, index);
    });
    Promise.all(promises).then((responses) => {
      const updatedDataFields = state.dataFields.map((field, index) => {
        if (field.answer instanceof File) {
          return {
            ...field,
            answer: responses[index]?.data?.data?.video,
          };
        }
        return field;
      });
      const fileIds = responses.map((response) => ({ id: response.data.data.dcId }));
      setState((prevState) => ({
        ...prevState,
        dataFields: updatedDataFields,
        ids: [...prevState.ids, ...fileIds]
      }));
      setIsDataFieldsUpdated(true);
    });
  };

  React.useEffect(() => {
    if ( isDataFieldsUpdated && state.dataFields.length > 0 && state.ids.length > 0) {
      completeUploadData(state);
      setIsDataFieldsUpdated(false);
    }
  }, [state.dataFields, state.ids, isDataFieldsUpdated]);

  
  const imageUploadS3 = (file, index) => {
    const apiname = "data_upload";
    var formData = new FormData();
    formData.append("pId", pId);
    formData.append("dataUpload", file);
    formData.append("user_id", isAuth().id);
    formData.append("contributor", isAuth().username);
    return services.post(apiname, formData).then((response) => {
      return response;
    });
  };


  const initialCard = () => (
    <React.Fragment>
    <div className="cfd-questions">
      {state.dataFields.length > 0 ? (
        state.dataFields.map((item, index) => (
          <div key={index.toString()}>
            {item.selectedValue === "IMAGE (.PNG)" || 
            item.selectedValue === "Image - Upload" ||
            item.selectedValue === "IMAGE (.JPG)" ||
            item.selectedValue === "Audio - Upload" ||  
            item.selectedValue === "AUDIO (.MP3)" || 
            item.selectedValue === 'Video - Upload' ||
             item.selectedValue === '3D - Upload' ||
             item.selectedValue === 'TEXT - Upload'  ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ margin: "5px 0 5px 0",color:"rgba(0, 0, 0, 0.54)"}}>
                  {item.question}
                </label>
                <div className="cfd-upload" style={{ height: "100px"}}>
                  <label htmlFor={`UploadImage${index}`} id="prompt">
                    {item?.answer ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                         src={getMediaImage(item?.answer)}
                          alt="Uploaded"
                          style={{ width: "70px", height: "70px" }}
                        />
                      </div>
                    ) : (
                      <div className="cfd-img-input" style={{
                      height:"100px",
                      lineHeight:"1"}}>
                        <h1
                          style={{
                            fontSize: "50px",
                            color: "#0000008a",
                            cursor: "pointer",
                            textAlign: "center",
                            margin:"0"
                          }}
                        >
                          +
                        </h1>
                        <label style={{ whiteSpace: "nowrap" }}>
                        {getFileUploadLabel(item.selectedValue)}
                        </label>
                      </div>
                    )}
                  </label>
                  <input
                    type="file"
                    id={`UploadImage${index}`}
                    name={`UploadImage${index}`}
                    label={item.question}
                    style={{ display: "none" }}
                    accept={getAcceptedFiles(item.selectedValue)}
                    onChange={(event) =>
                      updateDataFields(event, index, `UploadImage${index}`)
                    }
                  />
                </div>
              </div>
            ) : item.selectedValue === "IMAGE (.SVG)" ||
              item.selectedValue === "DRAW" ||  item.selectedValue === "Image - Draw"? (
              <>
                <label style={{ margin: "5px 0 5px 0" }}>
                  {item.question}
                </label>
                {showCanva ? (
                  <div className="Draw-upload-container">
                    <div className="cfd-Draw">
                      <p
                        style={{ color: "grey", cursor: "pointer" }}
                        onClick={handleCanva}
                      >
                        Clear
                      </p>
                      <DrawingArea onSvgFileChange={(svgFile) => updateSvgDataFields(index, svgFile)} />
                    </div>
                  </div>
                ) : (
                  <div
                    className="Draw-upload-container"
                    style={{ background: "white"}}
                  >
                    <div
                      className="cfd-upload-action"
                      style={{
                        width: "100%",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        padding: "25px 0 20px 0",
                        lineHeight: "1",
                      }}
                    >
                      <img
                        src={Pencil}
                        alt="drawicon"
                        onClick={handleCanva}
                        style={{ ...imageStyle, opacity: 0.54 }}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : item.selectedValue === "RECORD" || item.selectedValue === "Audio - Record"  ? (
              <>
               <label style={{ margin: "5px 0 5px 0" }}>
                  {item.question}
                </label>
              <div className="live-voice-container">
                {item.answer ? 
                 <audio src={URL.createObjectURL(item.answer)} controls/>
               
              :  <AudioRecorder onSaveAudio={handleSaveAudio} index={index} onDeleteAudio={handleDeleteAudio} />}
             
              </div>
              </>
            )
            : (item.selectedValue === "TEXT (.TXT)" || item.selectedValue === "TEXT - Write-In") && !hasAudioOrImage ? (
              <>
                <label style={{ margin: "5px 0 5px 0" }}>
                Upload data to this project
                </label>
                <div className="cfd-upload" style={{ height: "300px"}}>
                  {state.image ? (
                    <img
                      src={state.renderImage}
                      alt="uploaded"
                      style={UploadedimageStyle}
                    />
                  ) : (
                    <>
                      <div
                        className="text-center"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <div className="d-flex flex-column">
                          <label htmlFor="file" id="filelabel">
                            <h1 className="mb-5">+</h1>
                          </label>
                          <input
                            type="file"
                            id="file"
                            name="dataUpload"
                            onChange={handleImageUpload}
                            style={{ display: "none" }}
                            accept=".png,.jpg,.jpeg"
                          />
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              marginTop: "60px",
                            }}
                          >
                            Drop or choose a IMAGE file
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div style={{ marginTop: "10px" }}>
                        <CustomInputField
                          type="text"
                          label={item.question}
                          name="prompt1"
                          value={item.answer ? item.answer : ""}
                          placeholder="Enter info..."
                          style={{ fontFamily: "DM Sans" }}
                          onChange={(event) => updateDataFields(event, index)}
                        />
                      </div>
              </>
            ) : (
              <div style={{ marginTop: "10px" }}>
                <CustomInputField
                  type="text"
                  label={item.question}
                  name="prompt1"
                  value={item.answer ? item.answer : ""}
                  placeholder="Enter info..."
                  style={{ fontFamily: "DM Sans" }}
                  onChange={(event) => updateDataFields(event, index)}
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <>
          <label style={{ margin: "20px 0",fontWeight:"400",color:"rgba(0,0,0,0.5)" }}>Upload data to this project.</label>
          <div className="cfd-upload" style={{ height: "350px",marginTop:"5%"}}>
            {state.image ? (
              <img
                src={state.renderImage}
                alt="uploaded"
                style={UploadedimageStyle}
              />
            ) : (
              <>
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-column">
                    <label htmlFor="file" id="filelabel">
                      <h3 className="mb-5 small-font">
                        +
                        </h3>
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="dataUpload"
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                      accept=".png,.jpg,.jpeg"
                    />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        marginTop: "60px",
                      }}
                    >
                      Drop or choose a IMAGE file
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  </React.Fragment>
  );

  const SecondaryCard = () => (
    <React.Fragment>
      <label style={labelStyles}>
        Provenance is the chronology of an object’s ownership.
      </label>

      {(state.dataFields.length > 0 && hasAnswerWithExtension)||
      state.image ? (
        <div
          className="cfd-upload"
          style={{
            border: "none",
            backgroundColor: "transparent",
            flexDirection: "column",
          }}
        >
          {state.image ?
  <img src={URL?.createObjectURL(state.image)} alt="uploaded_image" style={UploadedimageStyle} />
  :  <img src={getMediaImage(mediaURL)} alt="uploaded_audio" style={UploadedimageStyle} />
}
          
          <label style={{ margin: "5px" }}>
            {
            state?.image ? state.image.name : mediaURL ? mediaURL?.name : ""}{" "}
            - ({isAuth()?.username ? isAuth().username : "Anonymous"})
          </label>
        </div>
      ) : (
        <div className="cfd-upload" style={{ height: "350px" }}>
          <div
            className="cfd-actions"
            style={{ width: "100%", alignItems: "center" }}
          >
            <label
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No uploaded files, please continue.
            </label>
          </div>
        </div>
      )}
      <div
        className="screenTwoCover"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        {(state?.dataFields.length > 0 && hasAnswerWithExtension) ||
        state.image ? (
          <div className="cfd-questions">
            <CustomInputField
              label="AUTHOR Or ATTRIBUTION"
              type="text"
              name="author"
              placeholder="Enter info..."
              value={state.author}
              onChange={(event) => setStateData(event)}
            />
            {/* <CustomInputField
             label="WHERE DID THIS COME FROM?"
             type="text"
             name="whereDidThisComeFrom"
             placeholder="Enter info..."
             value={state.whereDidThisComeFrom}
             onChange={(event) => setStateData(event)}
           />  */}
            <CustomInputField
              label="HOW WAS THIS SOURCED?"
              type="text"
              name="howWasThisSourced"
              placeholder="Enter info..."
              value={state.howWasThisSourced}
              onChange={(event) => setStateData(event)}
            />
          </div>
        ) : null}
        {state.optionalFields.length > 0
          ? state.optionalFields.map((item, index) => (
              <div style={{ marginBottom: "10px" }}>
                <CustomInputField
                  key={index.toString()}
                  label={item.question.toUpperCase()}
                  type="text"
                  name="prompt1"
                  placeholder="Enter info..."
                  value={state.question}
                  onChange={(event) => updateOptionalfields(event, index)}
                />
              </div>
            ))
          : null}
      </div>
    </React.Fragment>
  );

  const ThirdCard = () => (
    <React.Fragment>
      <label style={labelStyles}>
        Complete your contribution to the project by providing   <br />your consent
        below.
      </label>
      {/* <div className="cfd-questions">
        <CustomInputField
          label="EMAIL (OPTIONAL)"
          type="text"
          name="email"
          placeholder="Enter info..."
          value={state.email}
          onChange={(event) => setStateData(event)}
        />
        <CustomInputField
          label="DONATION OR PAID"
          type="text"
          name="donationOrPaid"
          placeholder="Enter info..."
          value={state.donationOrPaid}
          onChange={(event) => setStateData(event)}
        />
      </div> */}
      <div>
        <label
          style={{
            marginBottom: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "rgba(0,0,0,0.7)",
          }}
        >
          * indicates a required field
        </label>
        {/* <p
          style={{
            marginBottom: "15px",
            fontSize: "12px",
            textTransform: "uppercase",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          please select all that apply. please send any questions to
          data@poieto.com
        </p> */}
      </div>
      <div className="cfd-confirm-container">
        {/* <label style={labelConfirm}>
          <input
            type="checkbox"
            name="permissionOne"
            onChange={setPermissions}
            value="I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE"
          />
          I CERTIFY THAT I HAVE PERMISSION TO SHARE THIS FILE
        </label> */}
        <label style={labelConfirm1}>
          <input
            type="checkbox"
            name="permissionTwo"
            value="I consent to this data being used according to the data
                 settings of this project, for as long as the project
                 chooses to store the data"
            onChange={setPermissions}
            style={{ height: "20px" }}
          />
          <span className="spanconfirm">
            I CONSENT TO THIS DATA BEING USED ACCORDING TO THE DATA SETTINGS OF{" "}
            <br />
            THIS PROJECT, FOR AS LONG AS THE PROJECT CHOOSES TO STORE THE DATA*
          </span>
        </label>
        <label style={labelConfirm}>
          <input
            type="checkbox"
            name="permissionThree"
            onChange={setPermissions}
            value="CAN WE FOLLOW-UP WITH YOU ABOUT YOUR UPLOAD?"
            style={{ height: "20px" }}
          />
          CAN WE FOLLOW-UP WITH YOU ABOUT YOUR UPLOAD?
        </label>
      </div>
    </React.Fragment>
  );

  const FourthCard = () => (
    <React.Fragment>
      <div className="cfd-fourth-card">
        <label style={{ fontSize: "24px" }}> Success! </label>
        <label style={{ fontSize: "18px" }}>
          Your file has been submitted.{" "}
        </label>
        <div className="cfd-bgImg">
          <div className="yellowbox">
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "10px" }}>
                <img src={Ellipse} alt="user" />
              </div>
              {projName ? projName : projectName}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const showCards = () => {
    switch (currentCard) {
      case 1:
        return initialCard();
      case 2:
        return SecondaryCard();
      case 3:
        return ThirdCard();
      case 4:
        return FourthCard();
      default:
        return initialCard();
    }
  };
  const color = {
    color: "#4E4E4EB2",
  };

  const labelStyle = {
    margin: "5px 0",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.7)" // correct
  }

  const handleOnClick = () => {
    if (currentCard === 3) {
      submitForm();
      setTimeout(() => {
        handleNext();
      }, 4000);
    } else {
      handleNext();
    }
  };
  const [isExpanded, setIsExpanded] = React.useState(false);
  const charLimit = screenHeight - 150;
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const renderMessage = () => {
    if (!parentStates?.message) {
      return null;
    }

    if (parentStates.message.length <= charLimit || isExpanded) {
      return parentStates.message.includes('\n') ? (
        parentStates.message.split('\n').map((paragraph, index) => (
          <p
            key={index}
            style={{
              fontSize: '16px',
              textAlign: 'justify',
              marginTop: '5px',
              fontWeight: '400',
            }}
          >
            {paragraph}
          </p>
        ))
      ) : (
        <p
          style={{
            fontSize: '16px',
            textAlign: 'justify',
            marginTop: '5px',
            fontWeight: '400',
          }}
        >
          {parentStates.message}
        </p>
      );
    } else {
      return (
        <>
          <p
            style={{
              fontSize: '16px',
              textAlign: 'justify',
              marginTop: '5px',
              fontWeight: '400',
            }}
          >
            {parentStates.message.substring(0, charLimit)}...
          </p>
          <span
            onClick={toggleReadMore}
            style={{
              color: '#000000',
              cursor: 'pointer',
              fontSize: '16px',
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400"
            }}
          >
            Read more
          </span>
        </>
      );
    }
  };
  return (
    <div
      className="cfd-main-container"
      style={{ width: screenWidth - 20, height: "auto", zIndex: "1",marginTop:marginValue,justifyContent:justifyContent }}
    >
      <div className="cfd-info-container">
        <div className="yellowbox" style={{color:"rbga(0,0,0,0.7)"}}>
          <div className="d-flex align-items-center">
            <div style={{ marginRight: "10px" }}>
              <img src={Ellipse} alt="user" />
            </div>
            {projName ? projName : projectName}
          </div>
        </div>
        <label style={{ margin: "10px 0", fontSize: "32px" }}>
          {parentStates?.DataCallTitle?.toUpperCase()}
        </label>
        <label style={labelStyle}>THE CALL</label>
        <label style={{ ...labelStyle, textAlign: "justify" }}>
          {parentStates?.title}
        </label>
        <div className="cfd-side-Proj">
          <label style={{ marginTop: "20px", fontSize: "12px" }}>
            PROJECT DESCRIPTION
          </label>
          {renderMessage()}
      {isExpanded && parentStates.message.length > charLimit && (
        <span
          onClick={toggleReadMore}
          style={{
            color: '#000000',
            cursor: 'pointer',
            fontSize: '16px',
            display: 'block',
            marginTop: '10px',
            fontFamily: "DM Sans"
          }}
        >
          Show less
        </span>
      )}
        <br></br>
        <br></br>
          <label style={{ marginBottom: "10px", fontSize: "14px",color:"rgba(0, 0, 0, 0.7)"}}>
          DATA SETTINGS
          </label>
          <div className="Data-Privacy-container">
            <label style={{...labelStyle,margin:"2px"}}>Privacy</label>
            <label style={{color:'rgba(0, 0, 0, 0.70)'}}>{parentStates?.privacy}</label>
            <label style={{color:"rgba(0, 0, 0, 0.7)"}}>
  {parentStates?.privacy === "PRIVATE" 
    ? "Project is listed privately in our project" 
    : <>Project is listed publicly in our project database <br/> and others can request to join.</>}
</label>
          </div>
          {parentStates?.contributionTypes?.length > 0 &&
           <label style={{...labelStyle,margin:"2px"}}>Contribution Type</label>}
         
          {parentStates?.contributionTypes && parentStates?.contributionTypes.map((type, index) => (
  <div className="Data-Privacy-container" key={index} style={{margin:"0"}}>
    <label style={{color:'rgba(0, 0, 0, 0.70)'}}>{type}</label>
  </div>
))}
        </div>
      </div>
      <div className="cfd-container">
        <div>
          {currentCard < 4 ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="step" style={{ margin: "10px 0" }}>
                <div
                  className={
                    currentCard === 1
                      ? "activeStep"
                      : currentCard > 1
                      ? "inactiveStep"
                      : "transparentStep"
                  }
                ></div>
                <label style={color}>Data</label>
              </div>
              <div className="cfd-line"></div>
              <div className="step" style={{ margin: "10px 0" }}>
                <div
                  className={
                    currentCard === 2
                      ? "activeStep"
                      : currentCard > 2
                      ? "inactiveStep"
                      : "transparentStep"
                  }
                ></div>
                <label style={color}>Provenance</label>
              </div>
              <div className="cfd-line"></div>
              <div className="step" style={{ margin: "10px 0" }}>
                <div
                  className={
                    currentCard === 3 ? "activeStep" : "transparentStep"
                  }
                ></div>
                <label style={color}>Consent & Permissions</label>
              </div>
            </div>
          ) : null}

          {/* Using switch function to change the screens according to ScreenCount */}
          {showCards()}
        </div>
        {currentCard < 4 ? (
          <div className="cfd-button-group" style={{ marginTop: "auto" }}>
            <button className="cfd-back-action" onClick={handleBack}>
              Back
            </button>
            <button
              className="cfd-next-action"
              onClick={() => handleOnClick()}
              disabled={isLoading || state.isSubmitting || !hasAnswerWithExtension}
              style={{backgroundColor:!hasAnswerWithExtension ? "#464742":"black"}}
            >
              {currentCard === 3
                ? isLoading
                  ? "Submitting"
                  : "Submit"
                : "Next"}
            </button>
          </div>
        ) : (
          <div
            className="cfd-button-group"
            style={{ justifyContent: "center" }}
          >
            <button
              className="cfd-next-action"
              style={{ width: "80%" }}
              onClick={() => {
                closeModal(false);
              }}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadData;
