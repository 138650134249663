import React, { useState } from 'react';
import "./MultiSelectDropDown.css"
import Darrow from "../../Assets/Images/darrow.png"
import Uarrow from "../../Assets/Images/uarrow.png"
const MultiSelectTextInput = ({ options, placeholder, inputStyle = {}, onChange, dropdownStyle = {} }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const index = selectedOptions.findIndex((selectedOption) => selectedOption.name === option.name);
    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
      onChange([...selectedOptions, option]); // Pass the updated state directly
    } else {
      const updatedOptions = selectedOptions.filter(selectedOption => selectedOption.name !== option.name);
      setSelectedOptions(updatedOptions);
      onChange(updatedOptions); // Pass the updated state directly
      setIsOpen(isOpen ? false  : true); // Close the dropdown after selection
    }
  };

  const arrowStyle ={
    width:"15px",
    height:"10px"
  }

  return (
    <div className="custom-dropdown-container" style={dropdownStyle}>
      <div className="input-container" onClick={toggleDropdown}>
        <input
          type="text"
          placeholder={placeholder || 'Select an option'}
          style={inputStyle}
          onChange={onChange}
        //   value={selectedOptions.map(option => option.name).join(', ')}
          readOnly
        />
        <div className="placeholder-icon">{isOpen ? <img src={Uarrow} alt= "Uarrow" style={arrowStyle}/> :  <img src={Darrow} alt= "Darrow" style={arrowStyle}/>}</div>
      </div>
      {isOpen && (
        <div className="options-list">
          {options.map((option, index) => (
            <div
              key={index}
              className={`option ${selectedOptions.some(selectedOption => selectedOption.name === option.name) ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {option.name} {selectedOptions.some(selectedOption => selectedOption.name === option.name) ? <span className="option-icon"><i className="fas fa-check" ></i></span> : <span className="option-icon">&#10010;</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectTextInput;
